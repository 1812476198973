import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.commitment)
    ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                "prepend-icon": "mdi-link",
                variant: "text",
                href: _ctx.getCommitmentUrl(),
                target: "_blank",
                rel: "noopener noreferrer"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.commitment.Name), 1)
                ]),
                _: 1
              }, 8, ["href"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('StartAndEnd') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createTextVNode(" Start Date: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(_ctx.commitment.StartDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createTextVNode(" End Date: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(_ctx.commitment.EndDate)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('Customer') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createTextVNode(" Customer: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.Customers[0].CuFullName), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('Project') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[3] || (_cache[3] = _createTextVNode(" Project: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.ProjectDetails[0].ProjectName), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('Teammember') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createTextVNode(" Teammember: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.TeamMembers[0].TmFullName), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('PublicHolidayZone') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[5] || (_cache[5] = _createTextVNode(" Public Holiday Zone: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.PublicHolidayZoneName ?? "-"), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('ProjectLead') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[6] || (_cache[6] = _createTextVNode(" Project Lead: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.mergeName(_ctx.commitment.ProjectLeadDetail)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('PortfolioManager') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[7] || (_cache[7] = _createTextVNode(" Portfolio Manager: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.mergeName(_ctx.commitment.PortfolioManagerDetail)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('HoursPerWeek') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[8] || (_cache[8] = _createTextVNode(" Hours per week: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.HoursPerWeekRadio ? "Casual" : _ctx.commitment.HoursPerWeekMax ? _ctx.commitment.HoursPerWeekMax : "-"), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('LocationType') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[9] || (_cache[9] = _createTextVNode(" Location Type: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.LocationType ?? "-"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, {
                    "no-gutters": "",
                    class: "pl-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[10] || (_cache[10] = _createTextVNode(" Timezone: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.TimeZone ?? "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[11] || (_cache[11] = _createTextVNode(" Days of week: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.WokingDays ?? "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "6",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[12] || (_cache[12] = _createTextVNode(" From: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.StartWorkClock ?? "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "6",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[13] || (_cache[13] = _createTextVNode(" To: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.EndWorkClock ?? "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[14] || (_cache[14] = _createTextVNode(" Office Location: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.OfficeLocationName ?? "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[15] || (_cache[15] = _createTextVNode(" No. Of Office Days Per Week: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.OfficeDaysPerWeek ?? "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[16] || (_cache[16] = _createTextVNode(" Days Of Week In The Office: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.DaysOfWeekInOffice ?? "-"), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, { class: "pt-5 pb-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createElementVNode("b", null, "Addons:", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('TimesheetProcessing') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[18] || (_cache[18] = _createTextVNode(" Timesheet Processing: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.TimesheetProcessing ? "Enable" : "Disable"), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('Payroll') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[19] || (_cache[19] = _createTextVNode(" Payroll: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.SalaryCurrency ? "Enable" : "Disable"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, {
                    "no-gutters": "",
                    class: "pl-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[20] || (_cache[20] = _createTextVNode(" Salary: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.SeniorityLevel && _ctx.commitment.SeniorityLevel != 0 ? _ctx.commitment.SeniorityLevel : "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[21] || (_cache[21] = _createTextVNode(" Amount: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.SalaryMonthly ?? "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[22] || (_cache[22] = _createTextVNode(" Currency: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.SalaryCurrency ?? "-"), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('Contract') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[23] || (_cache[23] = _createTextVNode(" Contract: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.ContractManagement || _ctx.commitment.ContactVerification || _ctx.commitment.IDVerification ? "Enable" : "Disable"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, {
                    "no-gutters": "",
                    class: "pl-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[24] || (_cache[24] = _createTextVNode(" Contract Management: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.ContractManagement), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[25] || (_cache[25] = _createTextVNode(" Contact Information Verification: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.ContactVerification), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[26] || (_cache[26] = _createTextVNode(" ID Verification: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.IDVerification), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('VirtualDesktop') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[27] || (_cache[27] = _createTextVNode(" Virtual Desktop: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.VirtualDesktopSpec ? "Enable" : "Disable"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, {
                    "no-gutters": "",
                    class: "pl-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[28] || (_cache[28] = _createTextVNode(" Cpu: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.VirtualDesktopSpec ? _ctx.commitment.VirtualDesktopSpec.Cpu : "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[29] || (_cache[29] = _createTextVNode(" Memory: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.VirtualDesktopSpec ? _ctx.commitment.VirtualDesktopSpec.Ram : "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[30] || (_cache[30] = _createTextVNode(" Disk: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.VirtualDesktopSpec ? _ctx.commitment.VirtualDesktopSpec.Disk : "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[31] || (_cache[31] = _createTextVNode(" Location: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.VirtualDesktopSpec && _ctx.commitment.VirtualDesktopSpec.Location ? _ctx.commitment.VirtualDesktopSpec.Location : "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[32] || (_cache[32] = _createTextVNode(" Operating System : ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.VirtualDesktopSpec ? _ctx.commitment.VirtualDesktopSpec.OperatingSystem : "-"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[33] || (_cache[33] = _createTextVNode(" Security Level: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.VirtualDesktopSpec && _ctx.commitment.VirtualDesktopSpec.Level ? _ctx.commitment.VirtualDesktopSpec.Level : "-"), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('OfficeSpace') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[34] || (_cache[34] = _createTextVNode(" Office Space: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.OfficeSpaceId ? "Enable" : "Disable"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, {
                    "no-gutters": "",
                    class: "pl-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[35] || (_cache[35] = _createTextVNode(" Name: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.commitment.OfficeSpaceName ?? "-"), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_v_divider, { class: "border-opacity-50" }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: _normalizeClass({ 'bg-difference': _ctx.hasDifference('LineManager') })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _cache[36] || (_cache[36] = _createTextVNode(" Line Manager: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.commitment.LineManagerId ? "Enable" : "Disable"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, {
                    "no-gutters": "",
                    class: "pl-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "pa-0"
                      }, {
                        default: _withCtx(() => [
                          _cache[37] || (_cache[37] = _createTextVNode(" Name: ")),
                          _createElementVNode("b", null, _toDisplayString(_ctx.mergeName(_ctx.commitment.LineManagerDetail)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}