import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_NewTDM = _resolveComponent("NewTDM")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { md: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { loading: _ctx.loading }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _cache[8] || (_cache[8] = _createTextVNode(" TDMs ")),
                      _createVNode(_component_v_divider, {
                        class: "mx-4",
                        inset: "",
                        vertical: ""
                      }),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        dark: "",
                        onClick: _ctx.newItem
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("mdi-plus")
                            ])),
                            _: 1
                          }),
                          _cache[5] || (_cache[5] = _createTextVNode(" New TDM "))
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      (_ctx.showNewItem)
                        ? (_openBlock(), _createBlock(_component_v_dialog, {
                            key: 0,
                            modelValue: _ctx.showNewItem,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showNewItem) = $event)),
                            persistent: "",
                            "max-width": "600",
                            onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.showNewItem = false), ["esc"]))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_NewTDM, {
                                ref: "newTDM",
                                selectedManager: _ctx.selectedManager,
                                onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewItem = false)),
                                onSaved: _ctx.saved
                              }, null, 8, ["selectedManager", "onSaved"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]))
                        : _createCommentVNode("", true),
                      (_ctx.showLoginLinkDialog)
                        ? (_openBlock(), _createBlock(_component_v_dialog, {
                            key: 1,
                            modelValue: _ctx.showLoginLinkDialog,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showLoginLinkDialog) = $event)),
                            persistent: "",
                            "max-width": "600",
                            onKeydown: _withKeys(_ctx.cancelLoginLinkDialog, ["esc"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Login Link For " + _toDisplayString(_ctx.selectedFullName), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_text, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("a", {
                                        href: _ctx.selectedLoginLink,
                                        target: "_blank"
                                      }, _toDisplayString(_ctx.selectedLoginLink), 9, _hoisted_1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_actions, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        class: "secondary_btn",
                                        onClick: _ctx.cancelLoginLinkDialog
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, null, {
                                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                                              _createTextVNode("mdi-cancel")
                                            ])),
                                            _: 1
                                          }),
                                          _cache[7] || (_cache[7] = _createTextVNode(" cancel"))
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue", "onKeydown"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    items: _ctx.managers,
                    headers: _ctx.headers,
                    loading: _ctx.loading
                  }, {
                    "item.id": _withCtx(({ item }) => [
                      _createElementVNode("strong", null, _toDisplayString(item.id), 1)
                    ]),
                    "item.FirstName": _withCtx(({ item }) => [
                      _createElementVNode("span", null, _toDisplayString(item.FirstName), 1)
                    ]),
                    "item.LastName": _withCtx(({ item }) => [
                      _createElementVNode("span", null, _toDisplayString(item.LastName), 1)
                    ]),
                    "item.Email": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.Email), 1)
                    ]),
                    "item.IsActive": _withCtx(({ item }) => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        readonly: "",
                        modelValue: item.IsActive,
                        "onUpdate:modelValue": ($event: any) => ((item.IsActive) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    "item.Actions": _withCtx(({ item }) => [
                      _createVNode(_component_v_menu, {
                        "offset-y": "",
                        location: "bottom",
                        "scroll-strategy": "close"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps({
                            size: "small",
                            variant: "text",
                            color: "black"
                          }, props, { icon: "mdi-dots-vertical" }), null, 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => (_ctx.edit(item)),
                                "prepend-icon": "mdi-pencil-outline",
                                title: "Edit"
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => (_ctx.getLoginLink(item)),
                                "prepend-icon": "mdi-eye",
                                title: "Show Login Link"
                              }, null, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }, 8, ["items", "headers", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}