import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _cache[18] || (_cache[18] = _createElementVNode("span", null, " Add Notification", -1)),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  dark: "",
                  onClick: _ctx.cancel,
                  fab: "",
                  class: "error",
                  "x-small": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              ref: "frmNotification",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.valid) = $event)),
              "lazy-validation": "",
              class: "form-inputs"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "align-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          modelValue: _ctx.selectedUsers,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUsers) = $event)),
                          loading: _ctx.tmLoading,
                          disabled: _ctx.tmLoading,
                          items: _ctx.tempUsers,
                          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changed())),
                          "item-title": (item) => _ctx.generateUserLabel(item),
                          "item-value": "id",
                          outlined: "",
                          multiple: "",
                          chips: "",
                          clearable: "",
                          dense: "",
                          "deletable-chips": "",
                          rules: [_ctx.rules.required],
                          label: "Users"
                        }, null, 8, ["modelValue", "loading", "disabled", "items", "item-title", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          "hide-details": "",
                          label: "Active Users",
                          modelValue: _ctx.hasActiveUsers,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.hasActiveUsers) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          label: "Schedule",
                          "first-day-of-week": "1",
                          modelValue: _ctx.model.Schedule,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.Schedule) = $event)),
                          "hide-actions": "",
                          placeholder: "Date",
                          "prepend-icon": ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          "hide-details": "",
                          dense: "",
                          label: "Send Now",
                          modelValue: _ctx.model.SendNow,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.SendNow) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectField, {
                          modelValue: _ctx.selectedType,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedType) = $event)),
                          items: _ctx.types,
                          outlined: "",
                          dense: "",
                          rules: [_ctx.rules.required],
                          label: "Type"
                        }, null, 8, ["modelValue", "items", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectField, {
                          modelValue: _ctx.selectedSection,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedSection) = $event)),
                          items: _ctx.sections,
                          outlined: "",
                          dense: "",
                          rules: [_ctx.rules.required],
                          label: "Section"
                        }, null, 8, ["modelValue", "items", "rules"])
                      ]),
                      _: 1
                    }),
                    (_ctx.selectedSection === 'Other')
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.model.Url.trim())
                              ? (_openBlock(), _createBlock(_component_TextField, {
                                  key: 0,
                                  maxlength: 21,
                                  rules: [_ctx.rules.required],
                                  dense: "",
                                  modelValue: _ctx.customSection,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customSection) = $event)),
                                  label: "Other Section",
                                  outlined: ""
                                }, null, 8, ["rules", "modelValue"]))
                              : (_openBlock(), _createBlock(_component_TextField, {
                                  key: 1,
                                  dense: "",
                                  maxlength: 21,
                                  modelValue: _ctx.customSection,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customSection) = $event)),
                                  label: "Other Section",
                                  outlined: ""
                                }, null, 8, ["modelValue"]))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.selectedSection === 'Other')
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "12",
                          md: "9"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.customSection.trim())
                              ? (_openBlock(), _createBlock(_component_TextField, {
                                  key: 0,
                                  rules: [_ctx.rules.required],
                                  dense: "",
                                  modelValue: _ctx.model.Url,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.Url) = $event)),
                                  label: "Section Url",
                                  outlined: ""
                                }, null, 8, ["rules", "modelValue"]))
                              : (_openBlock(), _createBlock(_component_TextField, {
                                  key: 1,
                                  dense: "",
                                  modelValue: _ctx.model.Url,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.Url) = $event)),
                                  label: "Section Url",
                                  outlined: ""
                                }, null, 8, ["modelValue"]))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          dense: "",
                          label: "Push Text",
                          maxlength: 150,
                          modelValue: _ctx.model.PushText,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.PushText) = $event)),
                          outlined: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          rules: [_ctx.rules.required],
                          maxlength: 150,
                          dense: "",
                          label: "Short Description",
                          modelValue: _ctx.model.ShortDesc,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.ShortDesc) = $event)),
                          outlined: ""
                        }, null, 8, ["rules", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextAreaField, {
                          rules: [_ctx.rules.required],
                          dense: "",
                          label: "Text ",
                          modelValue: _ctx.model.Text,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.Text) = $event)),
                          outlined: ""
                        }, null, 8, ["rules", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextAreaField, {
                          dense: "",
                          label: "Sms Text",
                          modelValue: _ctx.model.SmsText,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.SmsText) = $event)),
                          outlined: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextAreaField, {
                          modelValue: _ctx.model.EmailText,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.EmailText) = $event)),
                          label: "Email Text",
                          outlined: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, !_ctx.pageLoading]
      ]),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "secondary_btn mr-5",
            variant: "elevated",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode("mdi-cancel")
                ])),
                _: 1
              }),
              _cache[20] || (_cache[20] = _createTextVNode(" cancel"))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            class: "primary_btn",
            variant: "elevated",
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode("mdi-check")
                ])),
                _: 1
              }),
              _cache[22] || (_cache[22] = _createTextVNode(" Save"))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}