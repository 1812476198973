import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_ProfileDropdown = _resolveComponent("ProfileDropdown")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        color: "primary",
        prominent: ""
      }, {
        default: _withCtx(() => [
          (_ctx.drawerPermanent)
            ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
                key: 0,
                onClick: _ctx.toggleDrawerOpen
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    id: "navigation-menu-mdi-chevron-right",
                    class: _normalizeClass({
            'v-icon': true,
            notranslate: true,
            mdi: true,
            'mdi-chevron-right': !_ctx.drawerIsOpen,
            'mdi-chevron-left': _ctx.drawerIsOpen,
          })
                  }, null, 2)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (!_ctx.drawerPermanent)
            ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
                key: 1,
                onClick: _ctx.toggleDrawerOpen
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_toolbar_items, { class: "hidden-sm-and-down" }, {
            default: _withCtx(() => [
              (!_ctx.isAuthenticated)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    variant: "text",
                    to: "/login"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Login")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.isAuthenticated)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    variant: "text",
                    to: "/logout"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Logout")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_ProfileDropdown, { key: 2 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_navigation_drawer, {
        dark: "",
        fixed: "",
        temporary: !_ctx.drawerPermanent,
        permanent: _ctx.drawerPermanent,
        "mini-variant": _ctx.drawerMini,
        modelValue: _ctx.drawerIsOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawerIsOpen) = $event)),
        "clipped-top": "",
        class: "app-drawer",
        width: "276"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NavigationMenu, {
            isAuthenticated: _ctx.isAuthenticated,
            isHiringmanager: _ctx.isHiringmanager,
            isSupport: _ctx.isSupport,
            userInfo: _ctx.userInfo
          }, null, 8, ["isAuthenticated", "isHiringmanager", "isSupport", "userInfo"])
        ]),
        _: 1
      }, 8, ["temporary", "permanent", "mini-variant", "modelValue"]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            fluid: "",
            class: "pages-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_Transition, {
                name: "slide-y-transition",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_loading_component, null, null, 512), [
                    [_vShow, _ctx.showLoading]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_router_view),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSnakbars, (snakbar, index) => {
                return (_openBlock(), _createBlock(_component_v_snackbar, {
                  left: "",
                  class: "snakbar",
                  bottom: "",
                  key: snakbar.id,
                  color: snakbar.status,
                  modelValue: snakbar.show,
                  "onUpdate:modelValue": ($event: any) => ((snakbar.show) = $event),
                  timeout: snakbar.timeout,
                  style: _normalizeStyle(`bottom: ${index * 60 + 10}px;`)
                }, {
                  actions: _withCtx(({ isActive }) => [
                    _createVNode(_component_v_btn, _mergeProps({
                      color: "white",
                      ripple: "",
                      variant: "text",
                      ref_for: true
                    }, isActive, {
                      onClick: ($event: any) => (_ctx.snakbarActionClicked(snakbar.id))
                    }), {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(snakbar.button), 1)
                      ]),
                      _: 2
                    }, 1040, ["onClick"])
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(snakbar.body) + " ", 1)
                  ]),
                  _: 2
                }, 1032, ["color", "modelValue", "onUpdate:modelValue", "timeout", "style"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}