<template>
  <v-card>
    <v-card-title> Update User Role </v-card-title>
    <v-card-text>
      <v-form ref="frmUser" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <SelectField
              :rules="[rules.required]"
              v-model="role"
              chips
              :items="[
                'Admin',
                'Hiringmanager',
                'Support',
                'Teammember',
                'Customer',
                'Linemanager',
                'Projectlead',
                'PortfolioManager',
                'Client',
                'TDM',
              ]"
              outlined
              label="Roles"
              dense
            >
            </SelectField>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="secondary_btn mr-5" variant="elevated" :disabled="loading" @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
      <v-btn class="primary_btn" variant="elevated" :loading="loading" @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import UserService from "@/services/UserService";
import { UserSetRoleModel } from "@/services/UserService";

export default defineComponent({
  props: ["selectedUser"],
  data() {
    return {
      valid: false,
      loading: false,
      role: "",
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        email: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid.",
      },
    };
  },
  async mounted() {
    this.role = this.selectedUser.role;
  },
  computed: {},
  methods: {
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmUser as any).validate();
      if (isValid.valid) {
        this.loading = true;
        await UserService.updateRole({
          userId: this.selectedUser.id,
          role: this.role,
        } as UserSetRoleModel);
        this.role = "";
        this.loading = false;
        (this.$refs.frmUser as any).reset();
        this.$emit("saved", JSON.stringify(this.role));
      }
    },
    cancel(): void {
      this.role = "";
      this.$emit("cancel");
    },
  },
});
</script>
