import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_NewRequestedJobTitleManagement = _resolveComponent("NewRequestedJobTitleManagement")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_NewRequestedSkillsManagement = _resolveComponent("NewRequestedSkillsManagement")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_tabs, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
      "align-tabs": "center",
      color: "deep-purple-accent-4",
      "fixed-tabs": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, { value: 1 }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Job Titles")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: 2 }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Skills")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_tabs_window, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_v_tabs_window_item, {
          key: 1,
          value: 1
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NewRequestedJobTitleManagement)
          ]),
          _: 1
        })),
        (_openBlock(), _createBlock(_component_v_tabs_window_item, {
          key: 2,
          value: 2
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NewRequestedSkillsManagement)
          ]),
          _: 1
        }))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}