import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_v_card, {
    title: "Skills",
    flat: ""
  }, {
    text: _withCtx(() => [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.searchQuery,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
        label: "Search",
        placeholder: "Search",
        "prepend-inner-icon": "mdi-magnify",
        variant: "outlined",
        "hide-details": "",
        "single-line": "",
        clearable: ""
      }, null, 8, ["modelValue"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_data_table, {
        disable: _ctx.tableLoading,
        loading: _ctx.tableLoading,
        headers: _ctx.headers,
        items: _ctx.skills,
        "item-value": "id",
        search: _ctx.searchQuery,
        modelValue: _ctx.selectedSkillIds,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSkillIds) = $event)),
        "multi-sort": "",
        "show-select": ""
      }, {
        "item.Text": _withCtx(({ index, item }) => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "10" }, {
                default: _withCtx(() => [
                  (_ctx.showTextField[index])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_v_text_field, {
                          modelValue: item.Text,
                          "onUpdate:modelValue": ($event: any) => ((item.Text) = $event),
                          maxlength: "120",
                          dense: "",
                          "hide-details": ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.Text), 1))
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  (item.id && _ctx.selectedSkillIds.includes(item.id))
                    ? (_openBlock(), _createBlock(_component_v_badge, {
                        key: 0,
                        color: _ctx.selectedSkillIds.indexOf(item.id) == 0 ? 'green' : 'error',
                        content: _ctx.selectedSkillIds.indexOf(item.id) + 1,
                        inline: ""
                      }, null, 8, ["color", "content"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        "item.actions": _withCtx(({ index, item }) => [
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.showTextField[index])
              ? _withDirectives((_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  class: "text-uppercase",
                  size: "small",
                  color: "primary",
                  text: "Edit",
                  onClick: ($event: any) => (_ctx.showTextField[index] = true)
                }, null, 8, ["onClick"])), [
                  [_directive_tooltip, 'Edit requested Skill']
                ])
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_v_btn, {
                    class: "mx-1",
                    size: "small",
                    color: "blue",
                    onClick: ($event: any) => (_ctx.saveItem(index, item))
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Done ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    class: "mx-1",
                    size: "small",
                    color: "red",
                    onClick: ($event: any) => (_ctx.showTextField[index] = false)
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]))
          ])
        ]),
        _: 1
      }, 8, ["disable", "loading", "headers", "items", "search", "modelValue"]),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                text: "Combine Selected",
                color: "secondary",
                class: "mr-5",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.combineSelectedSkillsByAdmin()))
              }),
              _createVNode(_component_v_btn, {
                text: "Approve Selected",
                color: "primary",
                class: "",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.approveSelectedSkillsByAdmin()))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}