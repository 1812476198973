import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[9] || (_cache[9] = _createElementVNode("span", null, " Add NotificationCreate new User", -1)),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "",
                dark: "",
                onClick: _ctx.cancel,
                fab: "",
                class: "error",
                "x-small": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmUser",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    xs: "6",
                    sm: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.firstName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.firstName) = $event)),
                        label: "First name",
                        outlined: ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.lastName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.lastName) = $event)),
                        label: "Last name",
                        outlined: ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required, _ctx.rules.email],
                        modelValue: _ctx.model.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.email) = $event)),
                        label: "Email",
                        outlined: ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.password,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.password) = $event)),
                        label: "Password",
                        outlined: ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SelectField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.role,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.role) = $event)),
                        chips: "",
                        items: ['Admin', 'Hiringmanager', 'Support', 'Teammember', 'Customer', 'LineManager', 'ProjectLead', 'PortfolioManager', 'SalesRep'],
                        outlined: "",
                        label: "Roles"
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  (_ctx.hasCustomerRole)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "12",
                        sm: "12",
                        md: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            modelValue: _ctx.model.customers,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.customers) = $event)),
                            rules: [_ctx.validateCustomers],
                            multiple: "",
                            chips: "",
                            "item-title": "Name",
                            "item-value": "id",
                            items: _ctx.customers,
                            outlined: "",
                            label: "User's Customer(s)"
                          }, null, 8, ["modelValue", "rules", "items"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_snackbar, {
            left: "",
            timeout: 5000,
            color: "error",
            modelValue: _ctx.showError,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showError) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorText), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "secondary_btn mr_5",
            variant: "elevated",
            disabled: _ctx.loading,
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("mdi-cancel")
                ])),
                _: 1
              }),
              _cache[11] || (_cache[11] = _createTextVNode(" cancel"))
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_v_btn, {
            class: "primary_btn",
            variant: "elevated",
            loading: _ctx.loading,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("mdi-check")
                ])),
                _: 1
              }),
              _cache[13] || (_cache[13] = _createTextVNode(" Save"))
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}