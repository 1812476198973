<template>
  <v-card>
    <v-card-title> {{ model && model.id ? "Edit" : "Add" }} Project Lead </v-card-title>
    <v-card-text>
      <v-form ref="frmRegister" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required]" outlined label="First Name" v-model="model.FirstName" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required]" outlined label="Last Name" v-model="model.LastName" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <TextField dense :rules="[rules.required, rules.email]" outlined label="Email" v-model="model.Email" />
          </v-col>
          <v-col class="pt-0 pb-0" md="12" sm="12" cols="12">
            <SelectField
              :rules="[rules.required]"
              :items="customers"
              item-title="text"
              item-value="id"
              v-model="model.AppliedCustomerId"
              label="Customer"
              dense
              outlined
            />
          </v-col>
          <v-col md="6" sm="6" class="12 pt-0 pb-0">
            <v-switch class="mt-1" v-model="model.IsActive" label="Is Active"></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="secondary_btn mr-5" @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>

      <v-btn class="primary_btn" @click="save" :loading="loading"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import ProjectLeadService from "shared-components/src/services/ProjectLeadService";
import ProjectLead from "shared-components/src/models/ProjectLead";
import CustomerService from "shared-components/src/services/CustomerService";

export default defineComponent({
  props: ["selectedLead"],
  data() {
    return {
      valid: true,
      model: {
        id: "",
        FirstName: "",
        LastName: "",
        Email: "",
        IsActive: false,
        AppliedCustomerId: "",
        AppliedCustomerName: "",
      },
      customers: [] as Array<{ id: string; text: string }>,
      pageLoading: true,
      loading: false,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        email: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
      },
    };
  },
  async mounted() {
    await this.populateCustomers();

    if (this.selectedLead && this.selectedLead.id) {
      this.model.id = this.selectedLead.id;
      this.model.FirstName = this.selectedLead.FirstName;
      this.model.LastName = this.selectedLead.LastName;
      this.model.Email = this.selectedLead.Email;
      this.model.IsActive = this.selectedLead.IsActive;
      this.model.AppliedCustomerId = this.selectedLead.AppliedCustomerId;
      this.model.AppliedCustomerName = this.selectedLead.AppliedCustomerName;
    }
  },
  methods: {
    async populateCustomers() {
      this.customers = (await CustomerService.getList())
        .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
        .map((item: any) => {
          return { id: item.id, text: `${item.FirstName} ${item.LastName}` };
        });
    },
    cancel(): void {
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmRegister as any).validate();
      if (isValid.valid) {
        this.loading = true;
        const item = { ...this.model } as ProjectLead;
        if (item.id) {
          await ProjectLeadService.update(item);
        } else {
          const savedProjectLeadId = await ProjectLeadService.set(item);
          item.id = savedProjectLeadId;
        }
        this.loading = false;
        this.$emit("saved", item);
      }
    },
  },
});
</script>
