<template>
  <v-card :loading="loading" :disabled="loading">
    <div v-show="!pageLoading">
      <v-card-title>
        <v-row no-gutters>
          <span>{{ selectedCommitmentId ? "Edit" : "Add" }} Commitment</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="frmCommitment" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="3" sm="6">
              <DateInputField
                id="start-date"
                :rules="[rules.required]"
                label="Start date"
                first-day-of-week="1"
                v-model="StartDate"
                hide-actions
                placeholder="Date"
                prepend-icon=""
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <DateInputField
                id="end-date"
                :rules="[rules.required]"
                label="End date"
                first-day-of-week="1"
                v-model="EndDate"
                hide-actions
                placeholder="Date"
                prepend-icon=""
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <AutoCompleteField
                id="select-customer"
                :rules="[rules.required]"
                label="Customer"
                v-model="selectedCustomerId"
                :items="customers"
                item-title="Name"
                item-value="id"
                outlined
                dense
                :readonly="model.HasTimesheet"
                @update:modelValue="onChangeCustomer"
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <AutoCompleteField
                id="select-project"
                :loading="projectLoading"
                :rules="[rules.required]"
                label="Project"
                v-model="model.ProjectId"
                :items="projects"
                item-title="Name"
                item-value="id"
                outlined
                :readonly="model.HasTimesheet"
                dense
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <AutoCompleteField
                id="select-teammember"
                :rules="[rules.required]"
                label="Teammember"
                v-model="model.TeamMemberId"
                :items="teammembers"
                item-title="Name"
                item-value="id"
                outlined
                dense
                :readonly="model.HasTimesheet"
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <SelectField
                id="select-public-holiday-zone"
                label="Public Holiday Zone"
                :items="publicHolidayZones"
                v-model="model.PublicHolidayZoneId"
                item-title="Name"
                item-value="id"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <AutoCompleteField
                id="select-project-lead"
                label="Project Lead"
                v-model="model.ProjectLeadId"
                :items="projectLeads"
                item-title="Name"
                item-value="id"
                outlined
                dense
                :readonly="model.HasTimesheet"
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <AutoCompleteField
                id="select-project-manager"
                label="Portfolio Manager"
                v-model="model.PortfolioManagerId"
                :items="portfolioManagers"
                item-title="Name"
                item-value="id"
                outlined
                :readonly="model.HasTimesheet"
                dense
              />
            </v-col>
          </v-row>

          <v-row class="align-center">
            <v-col cols="9" md="3" sm="6">
              <NumberField label="Hours per week" dense v-model="model.HoursPerWeek" :readonly="model.HoursPerWeekCasual" :min="1" outlined />
            </v-col>
            <v-col cols="3">
              <v-checkbox id="select-casual-hours-per-week" label="Casual" hide-details v-model="model.HoursPerWeekCasual" @update:modelValue="CasualChange" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-label>Location Type</v-label>
              <v-radio-group inline v-model="model.LocationType">
                <v-radio label="Remote" value="Remote"></v-radio>
                <v-radio label="On-Site" value="OnSite"></v-radio>
                <v-radio label="Hybrid" value="Hybrid"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="['Remote', 'OnSite', 'Hybrid'].includes(model.LocationType ?? '')">
            <v-col cols="4">
              <AutoCompleteField id="select-timezone" :rules="[rules.required]" label="Timezone" v-model="model.TimeZone" :items="timeZones" outlined dense />
            </v-col>
            <v-col cols="4" v-if="model.LocationType != 'Hybrid'">
              <AutoCompleteField
                id="select-days-of-week"
                :rules="[rules.required]"
                label="Days of Week"
                v-model="model.WorkingDays"
                :items="workingDays"
                multiple
                outlined
                chips
                dense
                @update:modelValue="DayOfWeekChanged"
              />
            </v-col>
            <v-col cols="2">
              <AutoCompleteField id="select-from-hour" :rules="[rules.required]" label="From" v-model="model.StartWorkClock" :items="hoursInDay" dense outlined />
            </v-col>
            <v-col cols="2">
              <AutoCompleteField id="select-to-hour" :rules="[rules.required]" label="To" v-model="model.EndWorkClock" :items="hoursInDay" dense outlined />
            </v-col>
          </v-row>
          <v-row v-if="['Remote', 'OnSite', 'Hybrid'].includes(model.LocationType ?? '')">
            <v-col cols="4" v-if="model.LocationType != 'Remote'">
              <AutoCompleteField
                outlined
                dense
                :rules="[rules.required]"
                :items="officeLocations"
                item-title="Name"
                item-value="Id"
                label="Office Location"
                showRequired
                v-model="model.OfficeLocationId"
                @update:modelValue="checkOfficeSpaceStatus"
              >
                <template v-slot:append>
                  <v-btn icon @click="showAddOfficeLocation = true" v-if="selectedCustomerId"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
              </AutoCompleteField>
            </v-col>
            <v-col cols="4" v-if="model.LocationType == 'Hybrid'">
              <AutoCompleteField
                v-model="model.OfficeDaysPerWeek"
                :rules="[rules.required]"
                label="No. Of Office Days Per Week"
                :items="NoOfficeDaysPerWeek"
                outlined
                dense
              />
            </v-col>
            <v-col cols="4" v-if="model.LocationType == 'Hybrid'">
              <AutoCompleteField
                v-model="model.DaysOfWeekInOffice"
                :rules="[rules.required]"
                label="Days Of Week In The Office"
                :items="dayInOffice"
                multiple
                outlined
                chips
                dense
                @update:modelValue="DaysInOfficeChanged"
                :return-object="false"
              />
            </v-col>
            <v-col cols="2"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card flat>
                <v-card-text>
                  <div class="d-flex justify-space-between align-center">
                    <div><h4>Addons</h4></div>
                    <div class="d-flex ga-2 justify-end">
                      <SelectField
                        :rules="[rules.required]"
                        :items="currencies"
                        v-model="selectedCurrency"
                        label="Currency"
                        placeholder="Currency"
                        @update:modelValue="updateCurrencies"
                      />
                      <div class="tax-input">
                        <NumberField :rules="[rules.required]" v-model="selectedTax" min="0" label="Tax" @update:modelValue="updateTaxes" />
                      </div>
                    </div>
                  </div>

                  <v-expansion-panels v-model="panelOpenState">
                    <v-expansion-panel hide-actions>
                      <v-expansion-panel-title :class="{ active: timeSheetProcessing.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="timeSheetProcessing.Enabled" label="Timesheet Processing" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <span>{{ selectedCurrency }} {{ timeSheetProcessing.Amount }} P/H</span>
                              <v-icon>mdi-information</v-icon>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <TimeSheetProcessingAddon :timeSheetProcessing="timeSheetProcessing" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel hide-actions>
                      <v-expansion-panel-title :class="{ active: virtualDesktopSpec.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="virtualDesktopSpec.Enabled" label="Virtual Desktop" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <span>{{ selectedCurrency }} {{ virtualDesktopSpec.Amount }} P/H</span>
                              <v-icon>mdi-information</v-icon>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <VirtualDesktopAddon
                          :isAddon="true"
                          :virtualDesktopSpec="virtualDesktopSpec"
                          :diskValues="diskValues"
                          :cpuValues="cpuValues"
                          :ramValues="ramValues"
                        />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel hide-actions :disabled="!virtualDesktopSpec.Enabled">
                      <v-expansion-panel-title :class="{ active: codeScan.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="codeScan.Enabled" label="Code Scan" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <span>{{ selectedCurrency }} {{ codeScan.Amount }} P/H</span>
                              <v-icon>mdi-information</v-icon>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <CodeScanAddon :codeScan="codeScan" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel hide-actions>
                      <v-expansion-panel-title :class="{ active: payRoll.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="payRoll.Enabled" label="Payroll" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <div class="d-flex flex-column ga-2 align-center">
                                <span>{{ selectedCurrency }} {{ payRoll.PayrollAmount }} P/H</span>
                                <span>
                                  {{ payRoll.SalaryCurrency || selectedCurrency }} {{ payRoll.SalaryAmount }}
                                  {{ payRoll.SalaryInterval == "Hourly" ? "P/H" : payRoll.SalaryInterval == "Monthly" ? "P/M" : "P/H" }}
                                </span>
                              </div>
                              <v-dialog max-width="500">
                                <template v-slot:activator="{ props }">
                                  <v-icon v-bind="props">mdi-information</v-icon>
                                </template>

                                <template v-slot:default="{ isActive }">
                                  <v-card>
                                    <v-card-text>
                                      <div class="d-flex flex-column">
                                        <h5>Benefits Management:</h5>
                                        <span class="ml-2">Administering employee benefits programs such as loans.</span>
                                        <span class="ml-2">Ensuring employees are aware of and utilizing available benefits.</span>
                                        <h5>Calculating Salaries and Wages:</h5>
                                        <span class="ml-2">Calculate base salary.</span>
                                        <span class="ml-2">Apply mandatory and voluntary deductions such as insurance, and loan repayments.</span>
                                        <h5>Processing Payroll Payments:</h5>
                                        <span class="ml-2">Transfer salaries electronically to employees' bank accounts.</span>
                                        <h5>Addressing Queries and Complaints:</h5>
                                        <span class="ml-2">Respond to employee queries and complaints regarding payroll.</span>
                                        <span class="ml-2">Resolve issues and discrepancies related to salary payments.</span>
                                        <h5>Reporting:</h5>
                                        <span class="ml-2">Prepare monthly, quarterly, and annual payroll reports.</span>
                                      </div>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn class="primary_btn" text="Close" @click="isActive.value = false"></v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <PayrollAddon :payRoll="payRoll" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel hide-actions>
                      <v-expansion-panel-title :class="{ active: contract.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="contract.Enabled" label="Contract" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <span>{{ selectedCurrency }} {{ contract.Amount }} P/H</span>
                              <v-dialog max-width="500" scrollable>
                                <template v-slot:activator="{ props }">
                                  <v-icon v-bind="props">mdi-information</v-icon>
                                </template>

                                <template v-slot:default="{ isActive }">
                                  <v-card>
                                    <v-card-text>
                                      <div class="d-flex flex-column">
                                        <h5>Document and Credential Verification:</h5>
                                        <span class="ml-2"
                                          >Collect and verify educational certificates, work experience documents, and identification proofs.</span
                                        >
                                        <span class="ml-2">Ensure the authenticity of documents and the accuracy of the information provided.</span>
                                        <span class="ml-2">Address, phone and email verification of TM.</span>
                                        <h5>Liaison with Relevant Authorities:</h5>
                                        <span class="ml-2">
                                          Communicate with educational institutions, previous employers, and other relevant authorities to verify
                                          information and records.
                                        </span>
                                        <span class="ml-2">Perform background checks and criminal record investigations (if necessary).</span>
                                        <h5>Contract Preparation and Drafting:</h5>
                                        <span class="ml-2"
                                          >Prepare and draft employment contracts based on legal standards and organizational policies.</span
                                        >
                                        <span class="ml-2"
                                          >Consult with the legal team to ensure the contract complies with labor laws and regulations.</span
                                        >
                                        <h5>Negotiation with Candidates:</h5>
                                        <span class="ml-2"
                                          >Conduct final negotiations with candidates regarding contract terms, benefits, and obligations.</span
                                        >
                                        <span class="ml-2"
                                          >Address candidates' questions and clarify any ambiguities regarding contract clauses.</span
                                        >
                                        <h5>Contract Signing:</h5>
                                        <span class="ml-2"
                                          >Schedule contract signing meetings with the candidate and organizational representatives.</span
                                        >
                                        <span class="ml-2">Collect necessary signatures and complete the contract signing process.</span>
                                        <h5>Onboarding and Orientation:</h5>
                                        <span class="ml-2"
                                          >Provide initial training and familiarize the candidate with the organization's culture and policies.</span
                                        >
                                        <span class="ml-2">Introduce the candidate to relevant teams and departments.</span>
                                        <h5>Record Keeping and Documentation:</h5>
                                        <span class="ml-2">Maintain and organize all documents and contracts related to employees.</span>
                                        <span class="ml-2">Manage and update personnel files regularly.</span>
                                        <h5>Compliance Monitoring:</h5>
                                        <span class="ml-2"
                                          >Ensure all legal and organizational regulations are followed during the verification and contract signing
                                          process.</span
                                        >
                                        <span class="ml-2">Implement internal policies and procedures to ensure compliance with standards.</span>
                                      </div>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn class="primary_btn" text="Close" @click="isActive.value = false"></v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <ContractAddon :contract="contract" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!-- <v-expansion-panel :disabled="commitmentModel.LocationType == 'Remote' || commitmentModel.OfficeLocationId != 'OfficeSpace'">
              <v-expansion-panel-title :class="{ active: model.officeSpace.Enabled }">
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">Office Space</v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <OfficeSpaceAddon :calculateOfficeSpace="calculateOfficeSpace" :officeSpace="model.officeSpace" :officeSpaces="officeSpaces" />
              </v-expansion-panel-text>
            </v-expansion-panel> -->
                    <v-expansion-panel hide-actions>
                      <v-expansion-panel-title :class="{ active: lineManagerAddon.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="lineManagerAddon.Enabled" label="Line Manager" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <span>{{ selectedCurrency }} {{ lineManagerAddon.Amount }} P/H</span>
                              <v-dialog max-width="500" scrollable>
                                <template v-slot:activator="{ props }">
                                  <v-icon v-bind="props">mdi-information</v-icon>
                                </template>

                                <template v-slot:default="{ isActive }">
                                  <v-card>
                                    <v-card-text>
                                      <div class="d-flex flex-column">
                                        <h5>Planning and Resource Allocation:</h5>
                                        <span class="ml-2"
                                          >Allocating resources and tasks to team members based on project priorities and needs.</span
                                        >
                                        <span class="ml-2">Planning and managing schedules to ensure goals and deadlines are met.</span>
                                        <h5>Communication and Coordination:</h5>
                                        <span class="ml-2"
                                          >Establishing effective communication with other departments and managers to coordinate activities and
                                          projects.</span
                                        >
                                        <span class="ml-2"
                                          >Facilitating internal team communications and creating a space for discussion and problem-solving.</span
                                        >
                                        <h5>Performance Management:</h5>
                                        <span class="ml-2">Setting performance goals and standards for team members.</span>
                                        <span class="ml-2"
                                          >Monitoring the performance and productivity of employees and taking corrective actions as needed.</span
                                        >
                                        <h5>Problem Solving and Conflict Resolution:</h5>
                                        <span class="ml-2"
                                          >Managing and resolving internal team problems and conflicts professionally and effectively.</span
                                        >
                                        <span class="ml-2">Providing appropriate solutions to enhance collaboration and reduce tensions.</span>
                                        <h5>Employee Development and Training:</h5>
                                        <span class="ml-2"
                                          >Identifying employees' training and development needs and providing training opportunities.</span
                                        >
                                        <span class="ml-2"
                                          >Assisting in the professional growth and development of employees through counseling and individual
                                          development planning.</span
                                        >
                                        <h5>Budget and Expense Management:</h5>
                                        <span class="ml-2">Monitoring the budget and expenses related to the team and projects.</span>
                                        <span class="ml-2">Ensuring optimal use of financial resources and achievement of financial goals.</span>
                                        <h5>Evaluation and Reporting:</h5>
                                        <span class="ml-2">Preparing periodic reports on team performance and project progress.</span>
                                        <span class="ml-2">Presenting reports and findings to senior managers and stakeholders.</span>
                                        <h5>Ensuring Compliance with Laws and Regulations:</h5>
                                        <span class="ml-2"
                                          >Ensuring compliance with organizational and governmental laws and regulations by the team.</span
                                        >
                                        <span class="ml-2"
                                          >Monitoring the implementation of organizational policies and processes within the team.</span
                                        >
                                      </div>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn class="primary_btn" text="Close" @click="isActive.value = false"></v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <LineManagerAddon :lineManagerAddon="lineManagerAddon" :lineManagers="lineManagers" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <!-- <v-expansion-panel>
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-start" cols="4">Traning Roadmaps</v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
            </v-expansion-panel> -->
                    <v-expansion-panel hide-actions>
                      <v-expansion-panel-title :class="{ active: tdm.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="tdm.Enabled" label="Talent Development Manager" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <span>{{ selectedCurrency }} {{ tdm.Amount }} P/H</span>
                              <v-icon>mdi-information</v-icon>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <TDMAddon :tdmModel="tdm" :tdms="tdms" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel hide-actions>
                      <v-expansion-panel-title :class="{ active: codeClanFund.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <div class="d-flex ga-2 align-center justify-space-between w-100">
                            <div class="d-flex ga-2 align-center">
                              <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                              <v-checkbox @click.stop v-model="codeClanFund.Enabled" label="Code Clan Fund" hide-details />
                            </div>
                            <div class="d-flex ga-2 align-center">
                              <span>{{ selectedCurrency }} {{ codeClanFund.Amount }} P/H</span>
                              <v-icon>mdi-information</v-icon>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <CodeClanFundAddon :codeClanFund="codeClanFund" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div class="mt-2 flex flex-column text-right">
                    <div>
                      Total:
                      <span>{{ selectedCurrency }} {{ getTotalAmount }} P/H</span>
                    </div>

                    <span
                      v-if="
                        payRoll.Enabled &&
                        (selectedCurrency != payRoll.SalaryCurrency || (payRoll.SalaryInterval && payRoll.SalaryInterval != 'Hourly'))
                      "
                    >
                      {{ payRoll.SalaryCurrency }} {{ payRoll.SalaryAmount ?? 0 }} {{ payRoll.SalaryInterval == "Hourly" ? "P/H" : "P/M" }}
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="chatMessages && chatMessages.length > 0">
          <v-col cols="12">
            <ChatBox title="Messages" :messages="chatMessages" :buttons="chatButtons" @sendMessage="sendMessage" :userId="getUserId" />
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <v-card-actions class="text-right">
      <v-btn class="secondary_btn mr-5" dark @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
      <v-btn id="save-commitment-btn" class="primary_btn" dark @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
    <loading-component v-if="pageLoading"></loading-component>
    <v-dialog v-model="showAddOfficeLocation" persistent max-width="400px">
      <AddOfficeLocation @cancel="showAddOfficeLocation = false" @saveLocation="saveOfficeLocation" :customerId="selectedCustomerId" />
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import CustomerService from "../../services/CustomerService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import Rate from "./Rate.vue";
import AddOfficeLocation from "./AddOfficeLocation.vue";
import {
  NotificationItem,
  OfficeLocationModel,
  ProjectApi,
  SendCommitmentNotificationRequestModel,
  GetCommitmentWithAddons,
  CommitmentAddons,
} from "shared-components/src/services/openApi/api";
import moment from "moment";
import { CommitmentApi, NotificationModel } from "shared-components/src/services/openApi";
import NotificationService from "@/services/NotificationService";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import { UserOfNotification } from "shared-components/src/services/openApi";
import store from "@/store";
import PayrollAddon from "shared-components/src/components/Commitment/Addons/PayrollAddon.vue";
import ContractAddon from "shared-components/src/components/Commitment/Addons/ContractAddon.vue";
import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue";
import LineManagerAddon from "shared-components/src/components/Commitment/Addons/LineManagerAddon.vue";
import OfficeSpaceAddon from "shared-components/src/components/Commitment/Addons/OfficeSpaceAddon.vue";
import TimeSheetProcessingAddon from "shared-components/src/components/Commitment/Addons/TimeSheetProcessingAddon.vue";
import ChatBoxMessage from "shared-components/src/models/ChatBoxMessage";
import ChatBoxButton from "shared-components/src/models/ChatBoxButton";
import ChatBox from "shared-components/src/components/ChatBox/ChatBox.vue";
import CodeScanAddon from "shared-components/src/components/Commitment/Addons/CodeScanAddon.vue";
import TDMAddon from "shared-components/src/components/Commitment/Addons/TDMAddon.vue";
var momentTz = require("moment-timezone");
import rules from "shared-components/src/utils/validations";
import CodeClanFundAddon from "shared-components/src/components/Commitment/Addons/CodeClanFundAddon.vue";

export default defineComponent({
  components: {
    LoadingComponent,
    Rate,
    AddOfficeLocation,
    TextField,
    PayrollAddon,
    ContractAddon,
    VirtualDesktopAddon,
    LineManagerAddon,
    OfficeSpaceAddon,
    TimeSheetProcessingAddon,
    ChatBox,
    CodeScanAddon,
    TDMAddon,
    CodeClanFundAddon,
  },
  props: [
    "commitments",
    "lineManagers",
    "publicHolidayZones",
    "projectLeads",
    "portfolioManagers",
    "tdms",
    "customers",
    "teammembers",
    "officeSpaces",
    "selectedCommitmentId",
  ],
  data() {
    return {
      rules,
      chatMessages: [] as ChatBoxMessage[],
      panelOpenState: null as null | number,
      chatButtons: [
        {
          Icon: "mdi-send-circle",
          Key: "sendCM",
          Text: "Send to Customer",
        },
        {
          Icon: "mdi-send-circle-outline",
          Key: "sendTM",
          Text: "Send To Teammember",
        },
      ] as ChatBoxButton[],
      ramValues: {
        0: "8",
        1: "16",
        2: "24",
        3: "32",
      },
      cpuValues: {
        0: "6",
        1: "8",
        2: "10",
      },
      diskValues: {
        0: "128",
        1: "256",
        2: "384",
        3: "512",
      },
      daysTemplate: [
        { title: "Flexible", value: "Flexible", props: { disabled: false } },
        { title: "SUN", value: "SUN", props: { disabled: false } },
        { title: "MON", value: "MON", props: { disabled: false } },
        { title: "TUE", value: "TUE", props: { disabled: false } },
        { title: "WED", value: "WED", props: { disabled: false } },
        { title: "THR", value: "THR", props: { disabled: false } },
        { title: "FRI", value: "FRI", props: { disabled: false } },
        { title: "SAT", value: "SAT", props: { disabled: false } },
      ],
      tdm: {
        Enabled: false,
        TdmId: null as string | null,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      codeScan: {
        Enabled: false,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      officeSpace: {
        Enabled: false,
        officeSpaceId: null as string | null,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      virtualDesktopSpec: {
        Cpu: 0,
        Disk: 0,
        Enabled: false,
        Level: null as string | null,
        Location: null as string | null,
        OperatingSystem: null as string | null,
        Ram: 0,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      timeSheetProcessing: {
        Enabled: false,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      lineManagerAddon: {
        Enabled: false,
        LineManagerId: null as string | null,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      payRoll: {
        Enabled: false,
        SalaryAmount: 0,
        SalaryCurrency: null as string | null,
        SalaryInterval: null as string | null,
        SalaryTax: 0,
        PayrollAmount: 0,
        PayrollCurrency: null as string | null,
        PayrollTax: 0,
      },
      contract: {
        Enabled: false,
        ContractManagement: false,
        ContactVerification: false,
        IDVerification: false,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      codeClanFund: {
        Enabled: false,
        Amount: 0,
        Currency: null as string | null,
        Tax: 0,
      },
      currencies: ["AUD", "PHP", "MYR", "USD", "SGD"],
      selectedCurrency: "AUD",
      selectedTax: 0,
      workingDays: [{ text: "Flexible", value: "Flexible", props: { disabled: false } }],
      dayInOffice: [{ text: "Flexible", value: "Flexible", props: { disabled: false } }],
      hoursInDay: ["Flexible"],
      NoOfficeDaysPerWeek: ["Flexible", 1, 2, 3, 4, 5, 6, 7],
      pageLoading: true,
      loading: false,
      valid: true,
      showRating: false,
      projectLoading: false,
      model: {} as GetCommitmentWithAddons,
      selectedCustomerId: null as string | null,
      timeZones: [] as Array<{ title: string; value: string }>,
      showAddOfficeLocation: false,
      StartDate: null as Date | null,
      EndDate: null as Date | null,
      projects: [] as Array<{ id: string; Name: string }>,
      officeLocations: [
        {
          Address: "Choose Office Space From CodeClan",
          Id: "OfficeSpace",
          Name: "Choose Office Space From CodeClan",
        },
      ] as OfficeLocationModel[],
    };
  },
  async mounted() {
    if (this.selectedCommitmentId) {
      await this.loadData();
      await this.fetchNotifications();
      await this.fetchOfficeLocations();
    }

    this.loadTimeZones();
    this.fillHoursInDay();
    this.workingDays = JSON.parse(JSON.stringify(this.daysTemplate));
    this.dayInOffice = JSON.parse(JSON.stringify(this.daysTemplate));
    this.pageLoading = false;
    this.updateCurrencies();
    this.updateTaxes();
  },
  methods: {
    updateTaxes() {
      this.payRoll.PayrollTax = this.selectedTax;
      this.codeClanFund.Tax = this.selectedTax;
      this.officeSpace.Tax = this.selectedTax;
      this.codeScan.Tax = this.selectedTax;
      this.contract.Tax = this.selectedTax;
      this.virtualDesktopSpec.Tax = this.selectedTax;
      this.tdm.Tax = this.selectedTax;
      this.lineManagerAddon.Tax = this.selectedTax;
      this.timeSheetProcessing.Tax = this.selectedTax;
    },
    updateCurrencies() {
      if (!this.payRoll.SalaryCurrency || this.payRoll.SalaryCurrency == "") {
        this.payRoll.SalaryCurrency = this.selectedCurrency;
      }
      this.payRoll.PayrollCurrency = this.selectedCurrency;
      this.codeClanFund.Currency = this.selectedCurrency;
      this.codeScan.Currency = this.selectedCurrency;
      this.officeSpace.Currency = this.selectedCurrency;
      this.contract.Currency = this.selectedCurrency;
      this.virtualDesktopSpec.Currency = this.selectedCurrency;
      this.tdm.Currency = this.selectedCurrency;
      this.lineManagerAddon.Currency = this.selectedCurrency;
      this.timeSheetProcessing.Currency = this.selectedCurrency;
    },
    getNotificationCreatedOn(date: string | undefined) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:MM");
      }
      return "";
    },
    getReceiverNames(users: UserOfNotification[] | undefined) {
      if (users) {
        return users.map((item) => item.UserFullName).join(",");
      }
      return "";
    },
    generateChatMessageItem(notification: NotificationItem) {
      return {
        CreatedOn: notification.CreationDate ?? "",
        CreatorUserId: notification.CreatorUserId,
        Message: notification.Text,
        SentBy: notification.CreatorUserFullName,
        ReceivedBy: [notification.UserFullName],
      } as ChatBoxMessage;
    },
    async sendMessage(event: any) {
      if (this.model && this.model.Id && event.Message != "") {
        const type = event.ButtonKey == "sendCM" ? "Client" : "Teammember";
        const request = { message: event.Message, receiverType: type } as SendCommitmentNotificationRequestModel;
        await NotificationService.sendCommitmentNotification(this.model.Id, request);
        await this.fetchNotifications();
      }
    },
    isOwner(message: NotificationModel) {
      if (message.CreatorUserId == store.state.userInfo.id) {
        return true;
      } else {
        return false;
      }
    },
    fillHoursInDay() {
      this.hoursInDay = ["Flexible"];
      this.hoursInDay = this.hoursInDay.concat(
        ...Array.from(Array(24), (_, hour) => [moment({ hour }).format("h:mm A"), moment({ hour, minute: 30 }).format("h:mm A")])
      );
    },
    async fetchOfficeLocations() {
      if (this.selectedCustomerId) {
        const response = await CustomerService.GetOfficeLocations(this.selectedCustomerId);
        this.officeLocations = this.officeLocations.concat(response);
      }
    },
    async fetchNotifications() {
      if (this.model && this.model.Id) {
        const notifications = await NotificationService.getByCommitmentId(this.model.Id);
        this.chatMessages = notifications.map((item) => {
          return this.generateChatMessageItem(item);
        });
      }
    },
    loadTimeZones() {
      var timeZones = momentTz.tz.names();

      for (var i in timeZones) {
        this.timeZones.push({
          title: timeZones[i] + " (GMT" + momentTz.tz(timeZones[i]).format("Z") + ")",
          value: timeZones[i],
        });
      }
    },
    CasualChange() {
      this.model.HoursPerWeek = undefined;
    },
    cancel() {
      (this.$refs.frmCommitment as any).reset();
      this.loading = false;
      this.$emit("cancel");
    },
    async save() {
      const isValid = await (this.$refs.frmCommitment as any).validate();
      if (isValid.valid) {
        try {
          this.loading = true;

          this.model.OfficeLocationId = this.model.OfficeLocationId == "OfficeSpace" ? undefined : this.model.OfficeLocationId;
          this.model.StartDate = moment(this.StartDate).format("YYYY-MM-DD");
          this.model.EndDate = moment(this.EndDate).format("YYYY-MM-DD");
          this.model.Addons = {
            CodeScanAddon: { Enabled: false },
            TdmAddon: { Enabled: false },
            TimesheetProcessingAddon: { Enabled: false },
            PayrollAddon: { Enabled: false },
            CodeClanFundAddon: { Enabled: false },
            VirtualDesktopAddon: { Enabled: false },
            LineManagerAddon: { Enabled: false },
            ContractAddon: { Enabled: false },
          } as CommitmentAddons;

          if (this.timeSheetProcessing.Enabled) {
            this.model.Addons.TimesheetProcessingAddon = {
              Amount: this.timeSheetProcessing.Amount,
              Currency: this.timeSheetProcessing.Currency ?? undefined,
              Enabled: true,
              Tax: this.timeSheetProcessing.Tax,
            };
          }

          if (this.virtualDesktopSpec.Enabled) {
            this.model.Addons.VirtualDesktopAddon = {
              Amount: this.virtualDesktopSpec.Amount,
              Cpu: Number(Object.values(this.cpuValues)[this.virtualDesktopSpec.Cpu]),
              Currency: this.virtualDesktopSpec.Currency ?? undefined,
              Disk: Number(Object.values(this.diskValues)[this.virtualDesktopSpec.Disk]),
              Enabled: true,
              Level: this.virtualDesktopSpec.Level ?? undefined,
              Location: this.virtualDesktopSpec.Location ?? undefined,
              OperatingSystem: this.virtualDesktopSpec.OperatingSystem ?? undefined,
              Ram: Number(Object.values(this.ramValues)[this.virtualDesktopSpec.Ram]),
              Tax: this.virtualDesktopSpec.Tax,
            };
          }

          if (this.lineManagerAddon.Enabled) {
            this.model.Addons.LineManagerAddon = {
              Enabled: true,
              LineManagerId: this.lineManagerAddon.LineManagerId ?? undefined,
              Amount: this.lineManagerAddon.Amount,
              Currency: this.lineManagerAddon.Currency ?? undefined,
              Tax: this.lineManagerAddon.Tax,
            };
          }

          if (this.payRoll.Enabled) {
            this.model.Addons.PayrollAddon = {
              Enabled: true,
              SalaryAmount: this.payRoll.SalaryAmount,
              SalaryCurrency: this.payRoll.SalaryCurrency ?? undefined,
              SalaryInterval: this.payRoll.SalaryInterval ?? undefined,
              SalaryTax: this.payRoll.SalaryTax,
              PayrollAmount: this.payRoll.PayrollAmount,
              PayrollCurrency: this.payRoll.PayrollCurrency ?? undefined,
              PayrollTax: this.payRoll.PayrollTax,
            };
          }

          if (this.contract.Enabled) {
            this.model.Addons.ContractAddon = {
              Enabled: true,
              Amount: this.contract.Amount,
              Currency: this.contract.Currency ?? undefined,
              ContactVerification: this.contract.ContactVerification,
              ContractManagement: this.contract.ContractManagement,
              IDVerification: this.contract.IDVerification,
              Tax: this.contract.Tax,
            };
          }

          if (this.codeScan.Enabled) {
            this.model.Addons.CodeScanAddon = {
              Enabled: true,
              Amount: this.codeScan.Amount,
              Currency: this.codeScan.Currency ?? undefined,
              Tax: this.codeScan.Tax,
            };
          }

          if (this.codeClanFund.Enabled) {
            this.model.Addons.CodeClanFundAddon = {
              Enabled: true,
              Amount: this.codeClanFund.Amount,
              Currency: this.codeClanFund.Currency ?? undefined,
              Tax: this.codeClanFund.Tax,
            };
          }

          if (this.tdm.Enabled) {
            this.model.Addons.TdmAddon = {
              Enabled: true,
              Amount: this.tdm.Amount,
              Currency: this.tdm.Currency ?? undefined,
              TdmId: this.tdm.TdmId ?? undefined,
              Tax: this.tdm.Tax,
            };
          }

          // if (addonsModel.officeSpace.Enabled) {
          //   this.model.OfficeSpaceId = addonsModel.officeSpace.officeSpaceId
          // } else {
          //   this.model.OfficeSpaceId = undefined
          // }

          if (this.model.Id) {
            await new CommitmentApi().updateCommitmentV2(this.model);
            store.dispatch("showSuccessMessage", "Commitment updated Successfully");
          } else {
            this.model.Id = (await new CommitmentApi().createCommitmentV2(this.model)).data;
            store.dispatch("showSuccessMessage", "Commitment created Successfully");
          }

          this.$emit("saved", this.model.Id);
        } finally {
          this.loading = false;
        }
      }
    },
    DayOfWeekChanged() {
      if (this.model.WorkingDays?.includes("Flexible")) {
        this.model.WorkingDays = ["Flexible"];
        this.workingDays.forEach((item: any) => (item.text != "Flexible" ? (item.props.disabled = true) : (item.props.disabled = false)));
      } else {
        this.workingDays.forEach((item: any) => (item.props.disabled = false));
      }
    },
    DaysInOfficeChanged() {
      if (this.model.DaysOfWeekInOffice && this.model.DaysOfWeekInOffice.includes("Flexible")) {
        this.model.DaysOfWeekInOffice = ["Flexible"];
        this.dayInOffice.forEach((item: any) => (item.text != "Flexible" ? (item.props.disabled = true) : (item.props.disabled = false)));
      } else {
        this.dayInOffice.forEach((item: any) => (item.props.disabled = false));
      }
    },
    async onChangeCustomer() {
      this.model.ProjectId = undefined;
      await this.fetchProjectsByCustomer();
      await this.fetchOfficeLocations();
    },
    async fetchProjectsByCustomer() {
      this.projectLoading = true;
      this.projects = [];
      if (this.selectedCustomerId) {
        this.projects = (await new ProjectApi().getByCustomerId(this.selectedCustomerId)).data.map((c: any) => {
          return { id: c.id, Name: c.Name };
        });
        this.projectLoading = false;
      }
    },
    async loadData(): Promise<void> {
      if (this.selectedCommitmentId) {
        this.loading = true;
        try {
          this.model = (await new CommitmentApi().getCommitmentByIdV2(this.selectedCommitmentId)).data;
          this.StartDate = moment(this.model.StartDate).toDate();
          this.EndDate = moment(this.model.EndDate).toDate();
          if (this.model.CustomerId) {
            this.selectedCustomerId = this.model.CustomerId;
            await this.fetchProjectsByCustomer();
          }

          if (this.model.Addons?.VirtualDesktopAddon && this.model.Addons?.VirtualDesktopAddon.Enabled) {
            this.virtualDesktopSpec = {
              Enabled: true,
              Amount: this.model.Addons?.VirtualDesktopAddon.Amount ?? 0,
              Currency: this.model.Addons?.VirtualDesktopAddon.Currency ?? null,
              Tax: this.model.Addons?.VirtualDesktopAddon.Tax ?? 0,
              Location: this.model.Addons?.VirtualDesktopAddon.Location ?? null,
              Level: this.model.Addons?.VirtualDesktopAddon.Level ?? null,
              OperatingSystem: this.model.Addons?.VirtualDesktopAddon.OperatingSystem ?? null,
              Cpu: Number(Object.keys(this.cpuValues).find((key) => (this.cpuValues as any)[key] == this.model.Addons?.VirtualDesktopAddon?.Cpu)),
              Ram: Number(Object.keys(this.ramValues).find((key) => (this.ramValues as any)[key] == this.model.Addons?.VirtualDesktopAddon?.Ram)),
              Disk: Number(Object.keys(this.diskValues).find((key) => (this.diskValues as any)[key] == this.model.Addons?.VirtualDesktopAddon?.Disk)),
            };
            this.selectedCurrency = this.model.Addons?.VirtualDesktopAddon.Currency ?? "AUD";
            this.selectedTax = this.model.Addons?.VirtualDesktopAddon.Tax ?? 0;
          }

          if (this.model.Addons?.TimesheetProcessingAddon && this.model.Addons?.TimesheetProcessingAddon.Enabled) {
            this.timeSheetProcessing = {
              Amount: this.model.Addons.TimesheetProcessingAddon.Amount ?? 0,
              Currency: this.model.Addons.TimesheetProcessingAddon.Currency ?? null,
              Enabled: true,
              Tax: this.model.Addons.TimesheetProcessingAddon.Tax ?? 0,
            };
            this.selectedCurrency = this.model.Addons?.TimesheetProcessingAddon.Currency ?? "AUD";
            this.selectedTax = this.model.Addons?.TimesheetProcessingAddon.Tax ?? 0;
          }

          if (this.model.Addons?.LineManagerAddon && this.model.Addons?.LineManagerAddon.Enabled) {
            this.lineManagerAddon = {
              Enabled: true,
              LineManagerId: this.model.Addons?.LineManagerAddon.LineManagerId ?? null,
              Amount: this.model.Addons?.LineManagerAddon.Amount ?? 0,
              Currency: this.model.Addons?.LineManagerAddon.Currency ?? null,
              Tax: this.model.Addons?.LineManagerAddon.Tax ?? 0,
            };
            this.selectedCurrency = this.model.Addons?.LineManagerAddon.Currency ?? "AUD";
            this.selectedTax = this.model.Addons?.LineManagerAddon.Tax ?? 0;
          }

          if (this.model.Addons?.PayrollAddon && this.model.Addons?.PayrollAddon.Enabled) {
            this.payRoll = {
              Enabled: true,
              SalaryAmount: this.model.Addons?.PayrollAddon.SalaryAmount ?? 0,
              SalaryCurrency: this.model.Addons?.PayrollAddon.SalaryCurrency ?? null,
              SalaryInterval: this.model.Addons?.PayrollAddon.SalaryInterval ?? null,
              SalaryTax: this.model.Addons?.PayrollAddon.SalaryTax ?? 0,
              PayrollAmount: this.model.Addons?.PayrollAddon.PayrollAmount ?? 0,
              PayrollCurrency: this.model.Addons?.PayrollAddon.PayrollCurrency ?? null,
              PayrollTax: this.model.Addons?.PayrollAddon.PayrollTax ?? 0,
            };
            this.selectedTax = this.model.Addons?.PayrollAddon.PayrollTax ?? 0;
          }

          if (this.model.Addons?.ContractAddon && this.model.Addons?.ContractAddon.Enabled) {
            this.contract = {
              Enabled: true,
              Amount: this.model.Addons?.ContractAddon.Amount ?? 0,
              Currency: this.model.Addons?.ContractAddon.Currency ?? null,
              ContactVerification: this.model.Addons?.ContractAddon.ContactVerification ?? false,
              ContractManagement: this.model.Addons?.ContractAddon.ContractManagement ?? false,
              IDVerification: this.model.Addons?.ContractAddon.IDVerification ?? false,
              Tax: this.model.Addons?.ContractAddon.Tax ?? 0,
            };
            this.selectedCurrency = this.model.Addons?.ContractAddon.Currency ?? "AUD";
            this.selectedTax = this.model.Addons?.ContractAddon.Tax ?? 0;
          }

          if (this.model.Addons?.CodeScanAddon && this.model.Addons?.CodeScanAddon.Enabled) {
            this.codeScan = {
              Enabled: true,
              Amount: this.model.Addons?.CodeScanAddon.Amount ?? 0,
              Currency: this.model.Addons?.CodeScanAddon.Currency ?? null,
              Tax: this.model.Addons?.CodeScanAddon.Tax ?? 0,
            };
            this.selectedCurrency = this.model.Addons?.CodeScanAddon.Currency ?? "AUD";
            this.selectedTax = this.model.Addons?.CodeScanAddon.Tax ?? 0;
          }

          if (this.model.Addons?.TdmAddon && this.model.Addons?.TdmAddon.Enabled) {
            this.tdm = {
              Enabled: true,
              Amount: this.model.Addons?.TdmAddon.Amount ?? 0,
              Currency: this.model.Addons?.TdmAddon.Currency ?? null,
              TdmId: this.model.Addons?.TdmAddon.TdmId ?? null,
              Tax: this.model.Addons?.TdmAddon.Tax ?? 0,
            };
            this.selectedCurrency = this.model.Addons?.TdmAddon.Currency ?? "AUD";
            this.selectedTax = this.model.Addons?.TdmAddon.Tax ?? 0;
          }

          if (this.model.Addons?.CodeClanFundAddon && this.model.Addons?.CodeClanFundAddon.Enabled) {
            this.codeClanFund = {
              Enabled: true,
              Amount: this.model.Addons?.CodeClanFundAddon.Amount ?? 0,
              Currency: this.model.Addons?.CodeClanFundAddon.Currency ?? null,
              Tax: this.model.Addons?.CodeClanFundAddon.Tax ?? 0,
            };
            this.selectedCurrency = this.model.Addons?.CodeClanFundAddon.Currency ?? "AUD";
            this.selectedTax = this.model.Addons?.CodeClanFundAddon.Tax ?? 0;
          }
        } finally {
          this.loading = false;
        }
      }
    },
    saveOfficeLocation(event: any) {
      this.showAddOfficeLocation = false;
      this.officeLocations.push(event);
    },
    checkOfficeSpaceStatus() {
      if (this.model.OfficeLocationId == "OfficeSpace") {
        this.officeSpace.Enabled = true;
      } else {
        this.officeSpace.Enabled = false;
      }
    },
  },
  watch: {
    "timeSheetProcessing.Enabled": {
      handler(newVal) {
        if (newVal) {
          this.panelOpenState = 0;
        }
      },
    },
    "virtualDesktopSpec.Enabled": {
      handler(newVal) {
        if (!newVal) {
          this.codeScan.Enabled = false;
          this.codeScan.Amount = 0;
        } else {
          this.panelOpenState = 1;
        }
      },
    },
    "codeScan.Enabled": {
      handler(newVal) {
        if (newVal) {
          this.panelOpenState = 2;
        }
      },
    },
    "payRoll.Enabled": {
      handler(newVal) {
        if (newVal) {
          this.panelOpenState = 3;
        }
      },
    },
    "contract.Enabled": {
      handler(newVal) {
        if (newVal) {
          this.panelOpenState = 4;
        }
      },
    },
    "lineManagerAddon.Enabled": {
      handler(newVal) {
        if (newVal) {
          this.panelOpenState = 5;
        }
      },
    },
    "tdm.Enabled": {
      handler(newVal) {
        if (newVal) {
          this.panelOpenState = 6;
        }
      },
    },
    "codeClanFund.Enabled": {
      handler(newVal) {
        if (newVal) {
          this.panelOpenState = 7;
        }
      },
    },
  },
  computed: {
    getTotalAmount() {
      const tmAmount = this.timeSheetProcessing.Amount ?? 0;
      const csAmount = this.codeScan.Amount ?? 0;
      const saAmount =
        this.payRoll.SalaryCurrency == this.selectedCurrency && this.payRoll.SalaryInterval == "Hourly" ? this.payRoll.SalaryAmount : 0;
      const prAmount = this.payRoll.PayrollAmount ?? 0;
      const ccfAmount = this.codeClanFund.Amount ?? 0;
      const coAmount = this.contract.Amount ?? 0;
      const vmAmount = this.virtualDesktopSpec.Amount ?? 0;
      const lmAmount = this.lineManagerAddon.Amount ?? 0;
      const tdmAmount = this.tdm.Amount ?? 0;
      return (
        Number(tmAmount) +
        Number(csAmount) +
        Number(prAmount) +
        Number(coAmount) +
        Number(vmAmount) +
        Number(lmAmount) +
        Number(saAmount) +
        Number(ccfAmount) +
        Number(tdmAmount)
      ).toFixed(2);
    },
    getUserId() {
      return store.state.userInfo.id;
    },
    calculateOfficeSpace() {
      let message = "You Selected";
      if (this.model.LocationType == "OnSite") {
        if (
          this.model.StartWorkClock == "Flexible" ||
          this.model.EndWorkClock == "Flexible" ||
          (this.model.WorkingDays && this.model.WorkingDays.includes("Flexible"))
        ) {
          return false;
        }
        const startTime = moment(this.model.StartWorkClock, "h:mm A");
        const endTime = moment(this.model.EndWorkClock, "h:mm A");
        const duration = moment.duration(endTime.diff(startTime));
        message = `${message} ${this.model.WorkingDays?.length} days per week (${this.model.WorkingDays?.join(",")}) and
          ${duration.asHours()} Hours per day`;
        return message;
      } else if (this.model.LocationType == "Hybrid") {
        if (this.model.StartWorkClock == "Flexible" || this.model.EndWorkClock == "Flexible" || this.model.OfficeDaysPerWeek == "Flexible") {
          return false;
        }
        const startTime = moment(this.model.StartWorkClock, "h:mm A");
        const endTime = moment(this.model.EndWorkClock, "h:mm A");
        const duration = moment.duration(endTime.diff(startTime));
        message = `${message} ${this.model.OfficeDaysPerWeek} days per week in these days (${this.model.DaysOfWeekInOffice?.join(
          ","
        )}) and ${duration.asHours()} Hours per day`;
        return message;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.active {
  background-color: $c_emerald;
}
.tax-input {
  width: 100px !important;
}
</style>
