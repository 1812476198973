import { Profile } from "shared-components/src/definitions/config";
import {
  SET_USER_INFO,
  OPEN_QUICK_LOGIN_DIALOG,
  CLOSE_QUICK_LOGIN_DIALOG,
  TOGGLE_DRAWER_OPEN,
  HANDLE_DRAWER_OPEN,
  SHOW_LOADING,
  HIDDEN_LOADING,
  REMOVE_SNAKBAR,
  SET_SNAKBAR_MODEL,
  ADD_COUNTRIES,
  ADD_CITIES
} from "./types";
import { CityModel, CountryModel } from "shared-components/src/services/openApi";
export const actions = {
  showErrorMessage({ commit }: any, message: string) {
    const model = {
      body: message,
      status: "error",
      button: "ok",
      timeout: Profile.ErrorSetting.SnackbarTimeout,
      show: true,
      handler: (snakbarId: any) => commit(REMOVE_SNAKBAR, snakbarId),
    };
    commit(SET_SNAKBAR_MODEL, model);
  },
  showSuccessMessage({ commit }: any, message: string) {
    const model = {
      body: message,
      status: "success",
      button: "ok",
      timeout: Profile.ErrorSetting.SnackbarTimeout,
      show: true,
      handler: (snakbarId: any) => commit(REMOVE_SNAKBAR, snakbarId),
    };
    commit(SET_SNAKBAR_MODEL, model);
  },
  showWarningMessage({ commit }: any, message: string) {
    const model = {
      body: message,
      status: "warning",
      button: "ok",
      timeout: Profile.ErrorSetting.SnackbarTimeout,
      show: true,
      handler: (snakbarId: any) => commit(REMOVE_SNAKBAR, snakbarId),
    }
    commit(SET_SNAKBAR_MODEL, model)
  },
  setUserInfo({ commit }: any, payload: any) {
    commit(SET_USER_INFO, payload);
  },
  openQuickLoginDialog({ commit }: any) {
    commit(OPEN_QUICK_LOGIN_DIALOG);
  },
  closeQuickLoginDialog({ commit }: any) {
    commit(CLOSE_QUICK_LOGIN_DIALOG);
  },
  toggleDrawerOpen({ commit }: any) {
    commit(TOGGLE_DRAWER_OPEN);
  },
  handleDrawerOpen({ commit }: any, value: boolean) {
    commit(HANDLE_DRAWER_OPEN, value);
  },
  showLoading({ commit }: any) {
    commit(SHOW_LOADING);
  },
  hiddenLoading({ commit }: any) {
    commit(HIDDEN_LOADING);
  },
  AddCountries({ commit }: any, countries: CountryModel[]) {
    commit(ADD_COUNTRIES, countries);
  },
  AddCities({ commit }: any, cities: CityModel[]) {
    commit(ADD_CITIES, cities);
  },  
};
