import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        md: "6",
        sm: "12",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, { ref: "frmLogin" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Sign In ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextField, {
                            id: "login-user-name",
                            outlined: "",
                            modelValue: _ctx.model.userName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.userName) = $event)),
                            "prepend-icon": "mdi-account",
                            label: "User Name",
                            type: "text",
                            class: "login-field",
                            rules: [_ctx.rules.required]
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextField, {
                            id: "login-password",
                            outlined: "",
                            modelValue: _ctx.model.password,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.password) = $event)),
                            "prepend-icon": "mdi-key",
                            label: "Password",
                            type: "password",
                            class: "login-field",
                            rules: [_ctx.rules.required]
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.rememberMe,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rememberMe) = $event)),
                            label: "Remember me"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            id: "login-button",
                            disabled: _ctx.loading,
                            loading: _ctx.loading,
                            color: "primary",
                            variant: "elevated",
                            onClick: _ctx.login,
                            "prepend-icon": "mdi-check"
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Login")
                            ])),
                            _: 1
                          }, 8, ["disabled", "loading", "onClick"]),
                          _createVNode(_component_v_spacer, {
                            class: _normalizeClass(_ctx.isMobile ? '' : 'd-none')
                          }, null, 8, ["class"]),
                          _createVNode(_component_v_btn, {
                            class: _normalizeClass(_ctx.isMobile ? 'mt-5 secondary_btn' : 'ml-5 secondary_btn'),
                            color: "secondary",
                            variant: "elevated",
                            to: "/resetPassword",
                            "prepend-icon": "mdi-update"
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" Reset password ")
                            ])),
                            _: 1
                          }, 8, ["class"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}