<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title> Loan Payment </v-card-title>
    <v-card-text>
      <v-form ref="frmApproval" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="6">
            <v-menu
              ref="startMenu"
              v-model="startMenuForPaymentDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ props }">
                <TextField
                  :rules="!model.IsRejected ? [rules.required] : []"
                  v-model="formatedPaymentDate"
                  label="Payment Date"
                  dense
                  outlined
                  v-bind="props"
                  :disabled="model.IsRejected"
                ></TextField>
              </template>
              <v-date-picker
                first-day-of-week="1"
                ref="picker"
                v-model="model.StartPaymentDate"
                @update:modelValue="startMenuForPaymentDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <TextField v-model="model.PaymentReference" dense outlined label="Payment Reference"></TextField>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="secondary_btn mr-5" @click="cancel"> cancel</v-btn>
      <v-btn class="primary_btn" @click="set"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import { LoanApprovalModel } from "shared-components/src/models/Loan";
export default defineComponent({
  props: ["loading", "value", "ProposedPaymentDate"],
  data() {
    return {
      valid: true,
      startMenuForProposedDate: false,
      startMenuForPaymentDate: false,
      endMenu: false,
      model: this.value as LoanApprovalModel,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
        currency: (v: any) => !v || /^(\-|\+)?[0-9,]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
      },
    };
  },
  methods: {
    resetModel(): void {
      this.model = this.value;
    },
    cancel(): void {
      this.$emit("cancel");
    },
    async set(): Promise<void> {
      const isValid = await (this.$refs.frmApproval as any).validate();
      if (isValid.valid) {
        this.$emit("payLoan", this.model);
      }
    },
  },
  computed: {
    formatedPaymentDate() {
      return this.model.StartPaymentDate ? Utils.toVsDateFormat(this.model.StartPaymentDate) : null;
    },
    formatedProposedDate() {
      return this.ProposedPaymentDate ? Utils.toVsDateFormat(this.ProposedPaymentDate) : null;
    },
  },
});
</script>
