import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between align-center" }
const _hoisted_2 = { class: "d-flex ga-2 justify-end" }
const _hoisted_3 = { class: "tax-input" }
const _hoisted_4 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_5 = { class: "d-flex ga-2 align-center" }
const _hoisted_6 = { class: "d-flex ga-2 align-center" }
const _hoisted_7 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_8 = { class: "d-flex ga-2 align-center" }
const _hoisted_9 = { class: "d-flex ga-2 align-center" }
const _hoisted_10 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_11 = { class: "d-flex ga-2 align-center" }
const _hoisted_12 = { class: "d-flex ga-2 align-center" }
const _hoisted_13 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_14 = { class: "d-flex ga-2 align-center" }
const _hoisted_15 = { class: "d-flex ga-2 align-center" }
const _hoisted_16 = { class: "d-flex flex-column ga-2 align-center" }
const _hoisted_17 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_18 = { class: "d-flex ga-2 align-center" }
const _hoisted_19 = { class: "d-flex ga-2 align-center" }
const _hoisted_20 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_21 = { class: "d-flex ga-2 align-center" }
const _hoisted_22 = { class: "d-flex ga-2 align-center" }
const _hoisted_23 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_24 = { class: "d-flex ga-2 align-center" }
const _hoisted_25 = { class: "d-flex ga-2 align-center" }
const _hoisted_26 = { class: "d-flex ga-2 align-center justify-space-between w-100" }
const _hoisted_27 = { class: "d-flex ga-2 align-center" }
const _hoisted_28 = { class: "d-flex ga-2 align-center" }
const _hoisted_29 = { class: "mt-2 flex flex-column text-right" }
const _hoisted_30 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_NumberField = _resolveComponent("NumberField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_TimeSheetProcessingAddon = _resolveComponent("TimeSheetProcessingAddon")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_CodeScanAddon = _resolveComponent("CodeScanAddon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_PayrollAddon = _resolveComponent("PayrollAddon")!
  const _component_ContractAddon = _resolveComponent("ContractAddon")!
  const _component_LineManagerAddon = _resolveComponent("LineManagerAddon")!
  const _component_TDMAddon = _resolveComponent("TDMAddon")!
  const _component_CodeClanFundAddon = _resolveComponent("CodeClanFundAddon")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ChatBox = _resolveComponent("ChatBox")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_AddOfficeLocation = _resolveComponent("AddOfficeLocation")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCommitmentId ? "Edit" : "Add") + " Commitment", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  dark: "",
                  onClick: _ctx.cancel,
                  fab: "",
                  class: "error",
                  "x-small": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              ref: "frmCommitment",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.valid) = $event)),
              "lazy-validation": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          id: "start-date",
                          rules: [_ctx.rules.required],
                          label: "Start date",
                          "first-day-of-week": "1",
                          modelValue: _ctx.StartDate,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.StartDate) = $event)),
                          "hide-actions": "",
                          placeholder: "Date",
                          "prepend-icon": ""
                        }, null, 8, ["rules", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          id: "end-date",
                          rules: [_ctx.rules.required],
                          label: "End date",
                          "first-day-of-week": "1",
                          modelValue: _ctx.EndDate,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.EndDate) = $event)),
                          "hide-actions": "",
                          placeholder: "Date",
                          "prepend-icon": ""
                        }, null, 8, ["rules", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          id: "select-customer",
                          rules: [_ctx.rules.required],
                          label: "Customer",
                          modelValue: _ctx.selectedCustomerId,
                          "onUpdate:modelValue": [
                            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCustomerId) = $event)),
                            _ctx.onChangeCustomer
                          ],
                          items: _ctx.customers,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: "",
                          readonly: _ctx.model.HasTimesheet
                        }, null, 8, ["rules", "modelValue", "items", "readonly", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          id: "select-project",
                          loading: _ctx.projectLoading,
                          rules: [_ctx.rules.required],
                          label: "Project",
                          modelValue: _ctx.model.ProjectId,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.ProjectId) = $event)),
                          items: _ctx.projects,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          readonly: _ctx.model.HasTimesheet,
                          dense: ""
                        }, null, 8, ["loading", "rules", "modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          id: "select-teammember",
                          rules: [_ctx.rules.required],
                          label: "Teammember",
                          modelValue: _ctx.model.TeamMemberId,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.TeamMemberId) = $event)),
                          items: _ctx.teammembers,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: "",
                          readonly: _ctx.model.HasTimesheet
                        }, null, 8, ["rules", "modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectField, {
                          id: "select-public-holiday-zone",
                          label: "Public Holiday Zone",
                          items: _ctx.publicHolidayZones,
                          modelValue: _ctx.model.PublicHolidayZoneId,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.PublicHolidayZoneId) = $event)),
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: ""
                        }, null, 8, ["items", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          id: "select-project-lead",
                          label: "Project Lead",
                          modelValue: _ctx.model.ProjectLeadId,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.ProjectLeadId) = $event)),
                          items: _ctx.projectLeads,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: "",
                          readonly: _ctx.model.HasTimesheet
                        }, null, 8, ["modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          id: "select-project-manager",
                          label: "Portfolio Manager",
                          modelValue: _ctx.model.PortfolioManagerId,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.PortfolioManagerId) = $event)),
                          items: _ctx.portfolioManagers,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          readonly: _ctx.model.HasTimesheet,
                          dense: ""
                        }, null, 8, ["modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "align-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "9",
                      md: "3",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NumberField, {
                          label: "Hours per week",
                          dense: "",
                          modelValue: _ctx.model.HoursPerWeek,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.HoursPerWeek) = $event)),
                          readonly: _ctx.model.HoursPerWeekCasual,
                          min: 1,
                          outlined: ""
                        }, null, 8, ["modelValue", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          id: "select-casual-hours-per-week",
                          label: "Casual",
                          "hide-details": "",
                          modelValue: _ctx.model.HoursPerWeekCasual,
                          "onUpdate:modelValue": [
                            _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.HoursPerWeekCasual) = $event)),
                            _ctx.CasualChange
                          ]
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_label, null, {
                          default: _withCtx(() => _cache[42] || (_cache[42] = [
                            _createTextVNode("Location Type")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_radio_group, {
                          inline: "",
                          modelValue: _ctx.model.LocationType,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.LocationType) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_radio, {
                              label: "Remote",
                              value: "Remote"
                            }),
                            _createVNode(_component_v_radio, {
                              label: "On-Site",
                              value: "OnSite"
                            }),
                            _createVNode(_component_v_radio, {
                              label: "Hybrid",
                              value: "Hybrid"
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (['Remote', 'OnSite', 'Hybrid'].includes(_ctx.model.LocationType ?? ''))
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              id: "select-timezone",
                              rules: [_ctx.rules.required],
                              label: "Timezone",
                              modelValue: _ctx.model.TimeZone,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.TimeZone) = $event)),
                              items: _ctx.timeZones,
                              outlined: "",
                              dense: ""
                            }, null, 8, ["rules", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        (_ctx.model.LocationType != 'Hybrid')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  id: "select-days-of-week",
                                  rules: [_ctx.rules.required],
                                  label: "Days of Week",
                                  modelValue: _ctx.model.WorkingDays,
                                  "onUpdate:modelValue": [
                                    _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.WorkingDays) = $event)),
                                    _ctx.DayOfWeekChanged
                                  ],
                                  items: _ctx.workingDays,
                                  multiple: "",
                                  outlined: "",
                                  chips: "",
                                  dense: ""
                                }, null, 8, ["rules", "modelValue", "items", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              id: "select-from-hour",
                              rules: [_ctx.rules.required],
                              label: "From",
                              modelValue: _ctx.model.StartWorkClock,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.StartWorkClock) = $event)),
                              items: _ctx.hoursInDay,
                              dense: "",
                              outlined: ""
                            }, null, 8, ["rules", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              id: "select-to-hour",
                              rules: [_ctx.rules.required],
                              label: "To",
                              modelValue: _ctx.model.EndWorkClock,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.EndWorkClock) = $event)),
                              items: _ctx.hoursInDay,
                              dense: "",
                              outlined: ""
                            }, null, 8, ["rules", "modelValue", "items"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (['Remote', 'OnSite', 'Hybrid'].includes(_ctx.model.LocationType ?? ''))
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                      default: _withCtx(() => [
                        (_ctx.model.LocationType != 'Remote')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  outlined: "",
                                  dense: "",
                                  rules: [_ctx.rules.required],
                                  items: _ctx.officeLocations,
                                  "item-title": "Name",
                                  "item-value": "Id",
                                  label: "Office Location",
                                  showRequired: "",
                                  modelValue: _ctx.model.OfficeLocationId,
                                  "onUpdate:modelValue": [
                                    _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.OfficeLocationId) = $event)),
                                    _ctx.checkOfficeSpaceStatus
                                  ]
                                }, {
                                  append: _withCtx(() => [
                                    (_ctx.selectedCustomerId)
                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                          key: 0,
                                          icon: "",
                                          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.showAddOfficeLocation = true))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[43] || (_cache[43] = [
                                                _createTextVNode("mdi-plus")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["rules", "items", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.model.LocationType == 'Hybrid')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 1,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  modelValue: _ctx.model.OfficeDaysPerWeek,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.OfficeDaysPerWeek) = $event)),
                                  rules: [_ctx.rules.required],
                                  label: "No. Of Office Days Per Week",
                                  items: _ctx.NoOfficeDaysPerWeek,
                                  outlined: "",
                                  dense: ""
                                }, null, 8, ["modelValue", "rules", "items"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.model.LocationType == 'Hybrid')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 2,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  modelValue: _ctx.model.DaysOfWeekInOffice,
                                  "onUpdate:modelValue": [
                                    _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.DaysOfWeekInOffice) = $event)),
                                    _ctx.DaysInOfficeChanged
                                  ],
                                  rules: [_ctx.rules.required],
                                  label: "Days Of Week In The Office",
                                  items: _ctx.dayInOffice,
                                  multiple: "",
                                  outlined: "",
                                  chips: "",
                                  dense: "",
                                  "return-object": false
                                }, null, 8, ["modelValue", "rules", "items", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_col, { cols: "2" })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { flat: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_1, [
                                  _cache[44] || (_cache[44] = _createElementVNode("div", null, [
                                    _createElementVNode("h4", null, "Addons")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_SelectField, {
                                      rules: [_ctx.rules.required],
                                      items: _ctx.currencies,
                                      modelValue: _ctx.selectedCurrency,
                                      "onUpdate:modelValue": [
                                        _cache[19] || (_cache[19] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
                                        _ctx.updateCurrencies
                                      ],
                                      label: "Currency",
                                      placeholder: "Currency"
                                    }, null, 8, ["rules", "items", "modelValue", "onUpdate:modelValue"]),
                                    _createElementVNode("div", _hoisted_3, [
                                      _createVNode(_component_NumberField, {
                                        rules: [_ctx.rules.required],
                                        modelValue: _ctx.selectedTax,
                                        "onUpdate:modelValue": [
                                          _cache[20] || (_cache[20] = ($event: any) => ((_ctx.selectedTax) = $event)),
                                          _ctx.updateTaxes
                                        ],
                                        min: "0",
                                        label: "Tax"
                                      }, null, 8, ["rules", "modelValue", "onUpdate:modelValue"])
                                    ])
                                  ])
                                ]),
                                _createVNode(_component_v_expansion_panels, {
                                  modelValue: _ctx.panelOpenState,
                                  "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.panelOpenState) = $event))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.timeSheetProcessing.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_4, [
                                              _createElementVNode("div", _hoisted_5, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[21] || (_cache[21] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.timeSheetProcessing.Enabled,
                                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.timeSheetProcessing.Enabled) = $event)),
                                                  label: "Timesheet Processing",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_6, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.timeSheetProcessing.Amount) + " P/H", 1),
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[45] || (_cache[45] = [
                                                    _createTextVNode("mdi-information")
                                                  ])),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_TimeSheetProcessingAddon, { timeSheetProcessing: _ctx.timeSheetProcessing }, null, 8, ["timeSheetProcessing"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.virtualDesktopSpec.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_7, [
                                              _createElementVNode("div", _hoisted_8, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[23] || (_cache[23] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.virtualDesktopSpec.Enabled,
                                                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.virtualDesktopSpec.Enabled) = $event)),
                                                  label: "Virtual Desktop",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_9, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.virtualDesktopSpec.Amount) + " P/H", 1),
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[46] || (_cache[46] = [
                                                    _createTextVNode("mdi-information")
                                                  ])),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_VirtualDesktopAddon, {
                                              isAddon: true,
                                              virtualDesktopSpec: _ctx.virtualDesktopSpec,
                                              diskValues: _ctx.diskValues,
                                              cpuValues: _ctx.cpuValues,
                                              ramValues: _ctx.ramValues
                                            }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, {
                                      "hide-actions": "",
                                      disabled: !_ctx.virtualDesktopSpec.Enabled
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.codeScan.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_10, [
                                              _createElementVNode("div", _hoisted_11, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[25] || (_cache[25] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.codeScan.Enabled,
                                                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.codeScan.Enabled) = $event)),
                                                  label: "Code Scan",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_12, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.codeScan.Amount) + " P/H", 1),
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[47] || (_cache[47] = [
                                                    _createTextVNode("mdi-information")
                                                  ])),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_CodeScanAddon, { codeScan: _ctx.codeScan }, null, 8, ["codeScan"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["disabled"]),
                                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.payRoll.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_13, [
                                              _createElementVNode("div", _hoisted_14, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[27] || (_cache[27] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.payRoll.Enabled,
                                                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.payRoll.Enabled) = $event)),
                                                  label: "Payroll",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_15, [
                                                _createElementVNode("div", _hoisted_16, [
                                                  _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.payRoll.PayrollAmount) + " P/H", 1),
                                                  _createElementVNode("span", null, _toDisplayString(_ctx.payRoll.SalaryCurrency || _ctx.selectedCurrency) + " " + _toDisplayString(_ctx.payRoll.SalaryAmount) + " " + _toDisplayString(_ctx.payRoll.SalaryInterval == "Hourly" ? "P/H" : _ctx.payRoll.SalaryInterval == "Monthly" ? "P/M" : "P/H"), 1)
                                                ]),
                                                _createVNode(_component_v_dialog, { "max-width": "500" }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                                                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                                                        _createTextVNode("mdi-information")
                                                      ])),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(({ isActive }) => [
                                                    _createVNode(_component_v_card, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_card_text, null, {
                                                          default: _withCtx(() => _cache[49] || (_cache[49] = [
                                                            _createElementVNode("div", { class: "d-flex flex-column" }, [
                                                              _createElementVNode("h5", null, "Benefits Management:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Administering employee benefits programs such as loans."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Ensuring employees are aware of and utilizing available benefits."),
                                                              _createElementVNode("h5", null, "Calculating Salaries and Wages:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Calculate base salary."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Apply mandatory and voluntary deductions such as insurance, and loan repayments."),
                                                              _createElementVNode("h5", null, "Processing Payroll Payments:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Transfer salaries electronically to employees' bank accounts."),
                                                              _createElementVNode("h5", null, "Addressing Queries and Complaints:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Respond to employee queries and complaints regarding payroll."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Resolve issues and discrepancies related to salary payments."),
                                                              _createElementVNode("h5", null, "Reporting:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Prepare monthly, quarterly, and annual payroll reports.")
                                                            ], -1)
                                                          ])),
                                                          _: 1
                                                        }),
                                                        _createVNode(_component_v_card_actions, null, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_spacer),
                                                            _createVNode(_component_v_btn, {
                                                              class: "primary_btn",
                                                              text: "Close",
                                                              onClick: ($event: any) => (isActive.value = false)
                                                            }, null, 8, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_PayrollAddon, { payRoll: _ctx.payRoll }, null, 8, ["payRoll"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.contract.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_17, [
                                              _createElementVNode("div", _hoisted_18, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[29] || (_cache[29] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.contract.Enabled,
                                                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.contract.Enabled) = $event)),
                                                  label: "Contract",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_19, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.contract.Amount) + " P/H", 1),
                                                _createVNode(_component_v_dialog, {
                                                  "max-width": "500",
                                                  scrollable: ""
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                                                      default: _withCtx(() => _cache[50] || (_cache[50] = [
                                                        _createTextVNode("mdi-information")
                                                      ])),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(({ isActive }) => [
                                                    _createVNode(_component_v_card, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_card_text, null, {
                                                          default: _withCtx(() => _cache[51] || (_cache[51] = [
                                                            _createElementVNode("div", { class: "d-flex flex-column" }, [
                                                              _createElementVNode("h5", null, "Document and Credential Verification:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Collect and verify educational certificates, work experience documents, and identification proofs."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Ensure the authenticity of documents and the accuracy of the information provided."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Address, phone and email verification of TM."),
                                                              _createElementVNode("h5", null, "Liaison with Relevant Authorities:"),
                                                              _createElementVNode("span", { class: "ml-2" }, " Communicate with educational institutions, previous employers, and other relevant authorities to verify information and records. "),
                                                              _createElementVNode("span", { class: "ml-2" }, "Perform background checks and criminal record investigations (if necessary)."),
                                                              _createElementVNode("h5", null, "Contract Preparation and Drafting:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Prepare and draft employment contracts based on legal standards and organizational policies."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Consult with the legal team to ensure the contract complies with labor laws and regulations."),
                                                              _createElementVNode("h5", null, "Negotiation with Candidates:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Conduct final negotiations with candidates regarding contract terms, benefits, and obligations."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Address candidates' questions and clarify any ambiguities regarding contract clauses."),
                                                              _createElementVNode("h5", null, "Contract Signing:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Schedule contract signing meetings with the candidate and organizational representatives."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Collect necessary signatures and complete the contract signing process."),
                                                              _createElementVNode("h5", null, "Onboarding and Orientation:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Provide initial training and familiarize the candidate with the organization's culture and policies."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Introduce the candidate to relevant teams and departments."),
                                                              _createElementVNode("h5", null, "Record Keeping and Documentation:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Maintain and organize all documents and contracts related to employees."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Manage and update personnel files regularly."),
                                                              _createElementVNode("h5", null, "Compliance Monitoring:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Ensure all legal and organizational regulations are followed during the verification and contract signing process."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Implement internal policies and procedures to ensure compliance with standards.")
                                                            ], -1)
                                                          ])),
                                                          _: 1
                                                        }),
                                                        _createVNode(_component_v_card_actions, null, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_spacer),
                                                            _createVNode(_component_v_btn, {
                                                              class: "primary_btn",
                                                              text: "Close",
                                                              onClick: ($event: any) => (isActive.value = false)
                                                            }, null, 8, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ContractAddon, { contract: _ctx.contract }, null, 8, ["contract"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.lineManagerAddon.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_20, [
                                              _createElementVNode("div", _hoisted_21, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[31] || (_cache[31] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.lineManagerAddon.Enabled,
                                                  "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.lineManagerAddon.Enabled) = $event)),
                                                  label: "Line Manager",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_22, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.lineManagerAddon.Amount) + " P/H", 1),
                                                _createVNode(_component_v_dialog, {
                                                  "max-width": "500",
                                                  scrollable: ""
                                                }, {
                                                  activator: _withCtx(({ props }) => [
                                                    _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                                                      default: _withCtx(() => _cache[52] || (_cache[52] = [
                                                        _createTextVNode("mdi-information")
                                                      ])),
                                                      _: 2
                                                    }, 1040)
                                                  ]),
                                                  default: _withCtx(({ isActive }) => [
                                                    _createVNode(_component_v_card, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_card_text, null, {
                                                          default: _withCtx(() => _cache[53] || (_cache[53] = [
                                                            _createElementVNode("div", { class: "d-flex flex-column" }, [
                                                              _createElementVNode("h5", null, "Planning and Resource Allocation:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Allocating resources and tasks to team members based on project priorities and needs."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Planning and managing schedules to ensure goals and deadlines are met."),
                                                              _createElementVNode("h5", null, "Communication and Coordination:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Establishing effective communication with other departments and managers to coordinate activities and projects."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Facilitating internal team communications and creating a space for discussion and problem-solving."),
                                                              _createElementVNode("h5", null, "Performance Management:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Setting performance goals and standards for team members."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Monitoring the performance and productivity of employees and taking corrective actions as needed."),
                                                              _createElementVNode("h5", null, "Problem Solving and Conflict Resolution:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Managing and resolving internal team problems and conflicts professionally and effectively."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Providing appropriate solutions to enhance collaboration and reduce tensions."),
                                                              _createElementVNode("h5", null, "Employee Development and Training:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Identifying employees' training and development needs and providing training opportunities."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Assisting in the professional growth and development of employees through counseling and individual development planning."),
                                                              _createElementVNode("h5", null, "Budget and Expense Management:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Monitoring the budget and expenses related to the team and projects."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Ensuring optimal use of financial resources and achievement of financial goals."),
                                                              _createElementVNode("h5", null, "Evaluation and Reporting:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Preparing periodic reports on team performance and project progress."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Presenting reports and findings to senior managers and stakeholders."),
                                                              _createElementVNode("h5", null, "Ensuring Compliance with Laws and Regulations:"),
                                                              _createElementVNode("span", { class: "ml-2" }, "Ensuring compliance with organizational and governmental laws and regulations by the team."),
                                                              _createElementVNode("span", { class: "ml-2" }, "Monitoring the implementation of organizational policies and processes within the team.")
                                                            ], -1)
                                                          ])),
                                                          _: 1
                                                        }),
                                                        _createVNode(_component_v_card_actions, null, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_spacer),
                                                            _createVNode(_component_v_btn, {
                                                              class: "primary_btn",
                                                              text: "Close",
                                                              onClick: ($event: any) => (isActive.value = false)
                                                            }, null, 8, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_LineManagerAddon, {
                                              lineManagerAddon: _ctx.lineManagerAddon,
                                              lineManagers: _ctx.lineManagers
                                            }, null, 8, ["lineManagerAddon", "lineManagers"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.tdm.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_23, [
                                              _createElementVNode("div", _hoisted_24, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[33] || (_cache[33] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.tdm.Enabled,
                                                  "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.tdm.Enabled) = $event)),
                                                  label: "Talent Development Manager",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_25, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.tdm.Amount) + " P/H", 1),
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[54] || (_cache[54] = [
                                                    _createTextVNode("mdi-information")
                                                  ])),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_TDMAddon, {
                                              tdmModel: _ctx.tdm,
                                              tdms: _ctx.tdms
                                            }, null, 8, ["tdmModel", "tdms"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, { "hide-actions": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.codeClanFund.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createElementVNode("div", _hoisted_26, [
                                              _createElementVNode("div", _hoisted_27, [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(expanded ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_checkbox, {
                                                  onClick: _cache[35] || (_cache[35] = _withModifiers(() => {}, ["stop"])),
                                                  modelValue: _ctx.codeClanFund.Enabled,
                                                  "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.codeClanFund.Enabled) = $event)),
                                                  label: "Code Clan Fund",
                                                  "hide-details": ""
                                                }, null, 8, ["modelValue"])
                                              ]),
                                              _createElementVNode("div", _hoisted_28, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.codeClanFund.Amount) + " P/H", 1),
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[55] || (_cache[55] = [
                                                    _createTextVNode("mdi-information")
                                                  ])),
                                                  _: 1
                                                })
                                              ])
                                            ])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_CodeClanFundAddon, { codeClanFund: _ctx.codeClanFund }, null, 8, ["codeClanFund"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]),
                                _createElementVNode("div", _hoisted_29, [
                                  _createElementVNode("div", null, [
                                    _cache[56] || (_cache[56] = _createTextVNode(" Total: ")),
                                    _createElementVNode("span", null, _toDisplayString(_ctx.selectedCurrency) + " " + _toDisplayString(_ctx.getTotalAmount) + " P/H", 1)
                                  ]),
                                  (
                        _ctx.payRoll.Enabled &&
                        (_ctx.selectedCurrency != _ctx.payRoll.SalaryCurrency || (_ctx.payRoll.SalaryInterval && _ctx.payRoll.SalaryInterval != 'Hourly'))
                      )
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.payRoll.SalaryCurrency) + " " + _toDisplayString(_ctx.payRoll.SalaryAmount ?? 0) + " " + _toDisplayString(_ctx.payRoll.SalaryInterval == "Hourly" ? "P/H" : "P/M"), 1))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            (_ctx.chatMessages && _ctx.chatMessages.length > 0)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChatBox, {
                          title: "Messages",
                          messages: _ctx.chatMessages,
                          buttons: _ctx.chatButtons,
                          onSendMessage: _ctx.sendMessage,
                          userId: _ctx.getUserId
                        }, null, 8, ["messages", "buttons", "onSendMessage", "userId"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, !_ctx.pageLoading]
      ]),
      _createVNode(_component_v_card_actions, { class: "text-right" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "secondary_btn mr-5",
            dark: "",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[57] || (_cache[57] = [
                  _createTextVNode("mdi-cancel")
                ])),
                _: 1
              }),
              _cache[58] || (_cache[58] = _createTextVNode(" cancel"))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            id: "save-commitment-btn",
            class: "primary_btn",
            dark: "",
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[59] || (_cache[59] = [
                  _createTextVNode("mdi-check")
                ])),
                _: 1
              }),
              _cache[60] || (_cache[60] = _createTextVNode(" Save"))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showAddOfficeLocation,
        "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.showAddOfficeLocation) = $event)),
        persistent: "",
        "max-width": "400px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AddOfficeLocation, {
            onCancel: _cache[39] || (_cache[39] = ($event: any) => (_ctx.showAddOfficeLocation = false)),
            onSaveLocation: _ctx.saveOfficeLocation,
            customerId: _ctx.selectedCustomerId
          }, null, 8, ["onSaveLocation", "customerId"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}