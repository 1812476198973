<template>
  <v-card title="Skills" flat>
    <template v-slot:text>
      <v-text-field
        v-model="searchQuery"
        label="Search"
        placeholder="Search"
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
        clearable
      ></v-text-field>
    </template>

    <v-data-table
      :disable="tableLoading"
      :loading="tableLoading"
      :headers="headers"
      :items="skills"
      item-value="id"
      :search="searchQuery"
      v-model="selectedSkillIds"
      multi-sort
      show-select
    >
      <template v-slot:item.Text="{ index, item }">
        <v-row>
          <v-col cols="10">
            <div v-if="showTextField[index]">
              <v-text-field v-model="item.Text" maxlength="120" dense hide-details></v-text-field>
            </div>
            <div v-else>{{ item.Text }}</div>
          </v-col>
          <v-col>
            <v-badge
              v-if="item.id && selectedSkillIds.includes(item.id)"
              :color="selectedSkillIds.indexOf(item.id) == 0 ? 'green' : 'error'"
              :content="selectedSkillIds.indexOf(item.id) + 1"
              inline
            >
            </v-badge>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actions="{ index, item }">
        <div class="text-center">
          <v-btn
            v-if="!showTextField[index]"
            class="text-uppercase"
            size="small"
            color="primary"
            text="Edit"
            v-tooltip="'Edit requested Skill'"
            @click="showTextField[index] = true"
          ></v-btn>

          <div v-else>
            <v-btn class="mx-1" size="small" color="blue" @click="saveItem(index, item)"> Done </v-btn>
            <v-btn class="mx-1" size="small" color="red" @click="showTextField[index] = false"> Cancel </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-col md="12">
        <v-btn text="Combine Selected" color="secondary" class="mr-5" @click="combineSelectedSkillsByAdmin()"> </v-btn>
        <v-btn text="Approve Selected" color="primary" class="" @click="approveSelectedSkillsByAdmin()"> </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CoreSkillApi, CoreSkillFullModel, CoreSkillModel } from "shared-components/src/services/openApi/api";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      searchQuery: "",
      searchTimeout: null,
      selectedSkillIds: [] as string[],
      tableLoading: false,
      showTextField: [] as boolean[],
      skills: [] as CoreSkillFullModel[],
      itemsPerPage: 10,
      headers: [
        { align: "start", key: "Text", title: "Skill Name", width: "300px" },
        { key: "CreatedAt", title: "Created At", width: "200px" },
        { key: "UpdatedAt", title: "Updated At", width: "200px" },
        { key: "RequestingUserId", title: "RequestingUserId", width: "300px" },
        { key: "IsRequested", title: "Is Requested", width: "50px" },
        { align: "center", key: "actions", title: "Actions", sortable: false, width: "200px" },
      ],
    };
  },
  async mounted() {
    await this.fetchSkills();
  },
  methods: {
    async approveSelectedSkillsByAdmin() {
      if (this.selectedSkillIds.length == 0) {
        store.dispatch("showWarningMessage", "Select At least one skill");
        return;
      }
      this.tableLoading = true;
      try {
        await new CoreSkillApi().approveRequestedSkillByAdmin(this.selectedSkillIds ?? "");
        this.skills.forEach((element) => {
          if (element.id && this.selectedSkillIds.includes(element.id)) {
            element.TeammemberId = undefined;
            element.IsRequested = false;
          }
        });
        store.dispatch("showSuccessMessage", "Approved Successfully!");
      } finally {
        this.tableLoading = false;
        this.selectedSkillIds = [];
      }
    },
    async combineSelectedSkillsByAdmin() {
      if (this.selectedSkillIds.length == 0) {
        store.dispatch("showWarningMessage", "Select At least one skill");
        return;
      }
      this.tableLoading = true;
      try {
        var requestBody = [] as CoreSkillModel[];
        this.skills.forEach((element) => {
          if (element.id && this.selectedSkillIds.includes(element.id)) {
            requestBody.push({ id: element.id, Text: element.Text, Value: (this.selectedSkillIds.indexOf(element.id) + 1).toString() });
          }
        });
        await new CoreSkillApi().combineRequestedSkillByAdmin(requestBody);
        store.dispatch("showSuccessMessage", "Updated Successfully!");
      } finally {
        this.tableLoading = false;
        this.selectedSkillIds = [];
        await this.fetchSkills();
      }
    },
    async fetchSkills() {
      this.tableLoading = true;
      try {
        var rsp = await new CoreSkillApi().getCoreSkillsWithDetails();
        this.skills = rsp.data;
        this.showTextField = new Array(this.skills.length).fill(false);
      } finally {
        this.tableLoading = false;
      }
    },
    async saveItem(index: number, skill: CoreSkillFullModel) {
      this.tableLoading = true;
      try {
        var requestBody = { id: skill.id, Text: skill.Text } as CoreSkillModel;
        await new CoreSkillApi().updateRequestedSkillByAdmin(requestBody);
        store.dispatch("showSuccessMessage", "Updated Successfully!");
      } finally {
        this.tableLoading = false;
        this.showTextField[index] = false;
      }
    },
  },
});
</script>
