<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row no-gutters>
        <span> Renew Commitment</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel" fab class="error" x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-form ref="s" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <DateInputField
              first-day-of-week="1"
              :rules="[rules.required]"
              v-model="cloneStartDate"
              label="Start date"
              outlined
              dense
              hide-actions
              placeholder="Select Date"
              prepend-icon=""
            ></DateInputField>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <DateInputField
              first-day-of-week="1"
              :rules="[rules.required]"
              v-model="cloneEndDate"
              label="End date"
              outlined
              dense
              hide-actions
              placeholder="Select Date"
              prepend-icon=""
            ></DateInputField>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="secondary_btn mr-5" dark @click="clone(true)"><v-icon>mdi-refresh</v-icon> Clone and expire old</v-btn>
      <v-btn class="primary_btn" dark @click="clone()"><v-icon>mdi-content-copy</v-icon> Clone Only</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import Commitment from "shared-components/src/models/Commitment";
import Utils from "shared-components/src/utils/Utils";
import Rate from "./Rate.vue";
import DateInputField from "shared-components/src/components/Fields/DateInputField.vue";

export default defineComponent({
  components: {
    LoadingComponent,
    Rate,
    DateInputField,
  },
  props: ["commitment"],
  data() {
    return {
      pageLoading: true,
      loading: false,
      valid: true,
      endDatePickerMenu: false,
      startDatePickerMenu: false,
      cloneStartDate: new Date() as Date | null,
      cloneEndDate: new Date() as Date | null,
      model: {} as Commitment,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
      },
    };
  },
  async mounted() {
    this.pageLoading = false;
    this.model = this.commitment;
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async clone(expireOld = false) {
      const isValid = await (this.$refs.s as any).validate();
      if (isValid.valid) {
        this.loading = true;
        var newCommitmentObject = { ...this.model };
        newCommitmentObject.StartDate = this.cloneStartDate;
        newCommitmentObject.EndDate = this.cloneEndDate;

        this.$emit("clone", { commitment: newCommitmentObject, expireOld });
      }
    },
  },
  computed: {
    selectedStartDate: {
      get(): string {
        return this.cloneStartDate ? Utils.toVsDateFormat(this.cloneStartDate) : "";
      },
      set(date: string | null): void {
        this.cloneStartDate = Utils.vsDateToDatetime(date || "");
      },
    },
    selectedEndDate: {
      get(): string {
        return this.cloneEndDate ? Utils.toVsDateFormat(this.cloneEndDate) : "";
      },
      set(date: string | null): void {
        this.cloneEndDate = Utils.vsDateToDatetime(date || "");
      },
    },
  },
});
</script>
