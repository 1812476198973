<template>
  <v-card>
    <v-card-title>
      {{ model.id ? "Update Activity" : "Add New Activity" }}
    </v-card-title>
    <v-card-text>
      <v-form ref="frmActivity" v-model="valid" lazy-validation>
        <v-row>
          <v-col xs="12" md="6">
            <TextField label="Activity Name" v-model="model.Value" :rules="[rules.required, rules.exist]" />
          </v-col>
          <v-col xs="12" md="6">
            <TextField label="Default Technology" v-model="model.DefaultTechnology" />
          </v-col>
          <v-col xs="6" md="6">
            <TextField label="Icon Class" v-model="model.IconClass" />
          </v-col>
          <v-col xs="6" md="6">
            <v-icon class="pt-6">{{ model.IconClass }}</v-icon>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn :disabled="loading" class="secondary_btn mr-5" dark @click="cancel"><v-icon>mdi-cancel</v-icon>Cancel</v-btn>
      <v-btn :loading="loading" class="primary_btn" dark @click="save"><v-icon>mdi-check</v-icon>{{ model.id ? "Update" : "Add" }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { Activity } from "shared-components/src/models/Activity";
import ActivityService from "shared-components/src/services/ActivityService";
import TechnologyService from "shared-components/src/services/TechnologyService";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["activityModel", "activityList"],
  data() {
    return {
      valid: true,
      loading: false,
      model: {
        DefaultTechnology: "",
        Value: null as string | null,
        id: null as string | null,
        IconClass: "",
      },
      rules: {
        required: (value: any) => !!value || "Required.",
        exist: (v: any) =>
          !this.activityList.some(
            (c: any) =>
              c.Activity &&
              c.Activity.Value &&
              v &&
              c.Activity.Value.toLowerCase() === v.toLowerCase().trim() &&
              !(c.Activity.id === this.activityModel?.id)
          ) || "Already exist",
      },
    };
  },
  async mounted() {
    if (this.activityModel?.id) {
      if (this.activityModel.DefaultTechnology) {
        var technology = await TechnologyService.getById(this.activityModel.DefaultTechnology);
        this.model.DefaultTechnology = technology.Value;
      }
      this.model.Value = this.activityModel.Value;
      this.model.id = this.activityModel.id;
      this.model.IconClass = this.activityModel.IconClass;
    }
  },
  methods: {
    resetModel(): void {
      this.model = {
        Value: null,
        id: null,
        DefaultTechnology: "",
        IconClass: "",
      };
      (this.$refs.frmActivity as any).reset();
    },
    cancel() {
      this.resetModel();
      this.loading = false;
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmActivity as any).validate();
      if (isValid.valid) {
        this.loading = true;
        if (this.model.IconClass) {
          this.model.IconClass = this.model.IconClass.toLowerCase();
        }
        if (this.model.id) {
          const model = {
            id: this.model.id,
            Value: this.model.Value,
            Disabled: this.activityModel.Disabled,
            DefaultTechnology: this.model.DefaultTechnology,
            IconClass: this.model.IconClass,
          } as Activity;
          var savedModel = await ActivityService.update(model);
          model.DefaultTechnology = savedModel.DefaultTechnology;
          this.resetModel();
          this.$emit("save", model);
        } else {
          const model = { Value: this.model.Value, DefaultTechnology: this.model.DefaultTechnology, IconClass: this.model.IconClass } as Activity;
          var savedModel = await ActivityService.addNew(model.Value, model.DefaultTechnology, model.IconClass);
          model.id = savedModel.id;
          model.DefaultTechnology = savedModel.DefaultTechnology;
          this.resetModel();
          this.$emit("save", model);
        }
      }
      this.loading = false;
    },
  },
});
</script>
