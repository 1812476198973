<template>
  <v-tabs v-model="tab" align-tabs="center" color="deep-purple-accent-4" fixed-tabs>
    <v-tab :value="1">Job Titles</v-tab>
    <v-tab :value="2">Skills</v-tab>
  </v-tabs>
  <v-tabs-window v-model="tab">
    <v-tabs-window-item :key="1" :value="1">
      <NewRequestedJobTitleManagement />
    </v-tabs-window-item>
    <v-tabs-window-item :key="2" :value="2">
      <NewRequestedSkillsManagement />
    </v-tabs-window-item>
  </v-tabs-window>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import NewRequestedJobTitleManagement from "./NewRequestedJobTitleManagement.vue";
import NewRequestedSkillsManagement from "./NewRequestedSkillsManagement.vue";

export default defineComponent({
  components: {
    NewRequestedJobTitleManagement,
    NewRequestedSkillsManagement,
  },
  data() {
    return {
      loading: false,
      tab: null,
    };
  },
});
</script>