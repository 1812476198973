import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_PersonalDetail = _resolveComponent("PersonalDetail")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_ExperienceDetail = _resolveComponent("ExperienceDetail")!
  const _component_Skill = _resolveComponent("Skill")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_LanguageSkillComponent = _resolveComponent("LanguageSkillComponent")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_EducationComponent = _resolveComponent("EducationComponent")!
  const _component_CareerHistory = _resolveComponent("CareerHistory")!
  const _component_CertificateComponent = _resolveComponent("CertificateComponent")!
  const _component_ContactComponent = _resolveComponent("ContactComponent")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_tabs, {
      "hide-slider": true,
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
      grow: "",
      "background-color": "transparent",
      "icons-and-text": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, { href: "#tab-1" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "mr-2" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("mdi-account-box")
              ])),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createTextVNode(" Personal Details "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { href: "#tab-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "mr-2" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("mdi-account-details")
              ])),
              _: 1
            }),
            _cache[13] || (_cache[13] = _createTextVNode(" Professional Details "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { href: "#tab-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "mr-2" }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("mdi-account-cog")
              ])),
              _: 1
            }),
            _cache[15] || (_cache[15] = _createTextVNode(" Skills "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { href: "#tab-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "mr-2" }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("mdi-school")
              ])),
              _: 1
            }),
            _cache[17] || (_cache[17] = _createTextVNode(" Education "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { href: "#tab-5" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "mr-2" }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("mdi-table-chair")
              ])),
              _: 1
            }),
            _cache[19] || (_cache[19] = _createTextVNode(" Career History "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { href: "#tab-6" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "mr-2" }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("mdi-certificate")
              ])),
              _: 1
            }),
            _cache[21] || (_cache[21] = _createTextVNode(" Certificate "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { href: "#tab-7" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "mr-2" }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode("mdi-phone")
              ])),
              _: 1
            }),
            _cache[23] || (_cache[23] = _createTextVNode(" Contact "))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          col: "12",
          lg: "12",
          sm: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tabs_window, {
              modelValue: _ctx.tab,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tabs_window_item, { value: "tab-1" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PersonalDetail, {
                      isHiringmanager: _ctx.isHiringmanager,
                      selectedTeammemberModel: _ctx.selectedTeammemberModel,
                      isAfterSignUp: false,
                      onScrollToElement: _ctx.scrollToElement,
                      onSnackbarMessage: _ctx.snackbarMessage,
                      loading: _ctx.submitting,
                      onSubmitPersonalDetail: _ctx.submitPersonalDetail,
                      personalDetailModel: _ctx.personalDetailModel
                    }, null, 8, ["isHiringmanager", "selectedTeammemberModel", "onScrollToElement", "onSnackbarMessage", "loading", "onSubmitPersonalDetail", "personalDetailModel"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tabs_window_item, { value: "tab-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ExperienceDetail, {
                      isHiringmanager: _ctx.isHiringmanager,
                      isAfterSignUp: false,
                      formLoading: _ctx.formLoading,
                      professionalDetailModel: _ctx.professionalDetailModel,
                      details: _ctx.details,
                      loading: _ctx.submitting,
                      teamMember: _ctx.teamMember,
                      sendReqeustLoading: _ctx.sendReqeustLoading,
                      onScrollToElement: _ctx.scrollToElement,
                      onSubmitExperienceDetail: _ctx.submitExperienceDetail,
                      onSubmitExperienceJobPreferences: _ctx.submitExperienceJobPreferences,
                      onRemoveJobPreferences: _ctx.removeJobPreferences,
                      onSendJobRequest: _ctx.sendJobRequest,
                      onGetProfessionalDetail: _ctx.getProfessionalDetail
                    }, null, 8, ["isHiringmanager", "formLoading", "professionalDetailModel", "details", "loading", "teamMember", "sendReqeustLoading", "onScrollToElement", "onSubmitExperienceDetail", "onSubmitExperienceJobPreferences", "onRemoveJobPreferences", "onSendJobRequest", "onGetProfessionalDetail"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tabs_window_item, { value: "tab-3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Skill, {
                              onSnackbarMessage: _ctx.snackbarMessage,
                              isHiringmanager: _ctx.isHiringmanager,
                              onShowLimitationMessage: _ctx.showLimitationMessage,
                              onSkillDownload: _ctx.skillDownload,
                              onShowSuccessMessage: _ctx.showSuccessMessage,
                              onShowErrorMessage: _ctx.showErrorMessage,
                              onSkillCertificateChanged: _ctx.skillCertificateChanged,
                              onGetSkills: _ctx.loadSkillsList,
                              onSaveSkill: _ctx.saveSkill,
                              onRemove: _ctx.remove,
                              onShowEditSkill: _ctx.showEditSkill,
                              onSendAddRequest: _ctx.sendSkillAddRequest,
                              onSaveRoleAndTitle: _ctx.saveRoleAndTitle,
                              onCloseEditModal: _ctx.closeEditModal,
                              details: _ctx.details,
                              uploadedCertificate: _ctx.uploadedCertificate,
                              sendReqeustLoading: _ctx.sendReqeustLoading,
                              showEditSkillModal: _ctx.showEditSkillModal,
                              addSkillLoading: _ctx.addSkillLoading,
                              removeSkillloading: _ctx.removeSkillloading,
                              skills: _ctx.skills,
                              teammemberSkills: _ctx.teammemberSkills,
                              tmModel: _ctx.teamMember,
                              saveRoleAndTitleLoading: _ctx.saveRoleAndTitleLoading
                            }, null, 8, ["onSnackbarMessage", "isHiringmanager", "onShowLimitationMessage", "onSkillDownload", "onShowSuccessMessage", "onShowErrorMessage", "onSkillCertificateChanged", "onGetSkills", "onSaveSkill", "onRemove", "onShowEditSkill", "onSendAddRequest", "onSaveRoleAndTitle", "onCloseEditModal", "details", "uploadedCertificate", "sendReqeustLoading", "showEditSkillModal", "addSkillLoading", "removeSkillloading", "skills", "teammemberSkills", "tmModel", "saveRoleAndTitleLoading"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_LanguageSkillComponent, {
                              isHiringmanager: _ctx.isHiringmanager,
                              onSnackbarMessage: _ctx.snackbarMessage,
                              isAfterSignUp: false,
                              formLoading: _ctx.formLoading,
                              languageSkills: _ctx.languages,
                              selectedTeammemberModel: _ctx.selectedTeammemberModel,
                              onShowSuccessMessage: _ctx.showSuccessMessage,
                              onShowErrorMessage: _ctx.showErrorMessage,
                              onGetLanguageSkills: _ctx.getLanguageSkills
                            }, null, 8, ["isHiringmanager", "onSnackbarMessage", "formLoading", "languageSkills", "selectedTeammemberModel", "onShowSuccessMessage", "onShowErrorMessage", "onGetLanguageSkills"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tabs_window_item, { value: "tab-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_EducationComponent, {
                      onSnackbarMessage: _ctx.snackbarMessage,
                      isHiringmanager: _ctx.isHiringmanager,
                      onScrollToElement: _ctx.scrollToElement,
                      selectedTeammemberModel: _ctx.selectedTeammemberModel,
                      onShowSuccessMessage: _ctx.showSuccessMessage,
                      onShowErrorMessage: _ctx.showErrorMessage,
                      formLoading: _ctx.formLoading,
                      educations: _ctx.educations,
                      onGetEducations: _ctx.getEducations
                    }, null, 8, ["onSnackbarMessage", "isHiringmanager", "onScrollToElement", "selectedTeammemberModel", "onShowSuccessMessage", "onShowErrorMessage", "formLoading", "educations", "onGetEducations"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tabs_window_item, { value: "tab-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CareerHistory, {
                      isHiringmanager: _ctx.isHiringmanager,
                      isAfterSignUp: false,
                      onSnackbarMessage: _ctx.snackbarMessage,
                      onShowSuccessMessage: _ctx.showSuccessMessage,
                      onShowErrorMessage: _ctx.showErrorMessage,
                      selectedTeammemberModel: _ctx.selectedTeammemberModel,
                      onSendJobRequest: _ctx.sendJobRequest,
                      onGetCareerHistories: _ctx.getCareerHistories,
                      onScrollToElement: _ctx.scrollToElement,
                      onSetCareerHistoryDescriptionState: _ctx.setCareerHistoryDescriptionState,
                      onUpdateJobTitle: _ctx.updateJobTitle,
                      formLoading: _ctx.formLoading,
                      skills: _ctx.skills,
                      careerHistories: _ctx.careerHistories,
                      careerHistoryDescription: _ctx.careerHistoryDescription,
                      teammemberSkills: _ctx.teammemberSkills,
                      submitting: _ctx.submitting,
                      details: _ctx.details
                    }, null, 8, ["isHiringmanager", "onSnackbarMessage", "onShowSuccessMessage", "onShowErrorMessage", "selectedTeammemberModel", "onSendJobRequest", "onGetCareerHistories", "onScrollToElement", "onSetCareerHistoryDescriptionState", "onUpdateJobTitle", "formLoading", "skills", "careerHistories", "careerHistoryDescription", "teammemberSkills", "submitting", "details"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tabs_window_item, { value: "tab-6" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CertificateComponent, {
                      onSnackbarMessage: _ctx.snackbarMessage,
                      isHiringmanager: _ctx.isHiringmanager,
                      onShowSuccessMessage: _ctx.showSuccessMessage,
                      onShowErrorMessage: _ctx.showErrorMessage,
                      selectedTeammemberModel: _ctx.selectedTeammemberModel,
                      formLoading: _ctx.formLoading,
                      skills: _ctx.skills,
                      teammemberSkills: _ctx.teammemberSkills,
                      certificates: _ctx.certificates,
                      onGetCertificates: _ctx.getCertificates,
                      onScrollToElement: _ctx.scrollToElement
                    }, null, 8, ["onSnackbarMessage", "isHiringmanager", "onShowSuccessMessage", "onShowErrorMessage", "selectedTeammemberModel", "formLoading", "skills", "teammemberSkills", "certificates", "onGetCertificates", "onScrollToElement"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tabs_window_item, { value: "tab-7" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ContactComponent, {
                      onSnackbarMessage: _ctx.snackbarMessage,
                      isHiringmanager: _ctx.isHiringmanager,
                      selectedTeammemberModel: _ctx.selectedTeammemberModel,
                      onShowErrorMessage: _ctx.showErrorMessage,
                      isAfterSignUp: false,
                      addressList: _ctx.addresses,
                      phoneList: _ctx.phoneNumbers,
                      addressSendCodeLoading: _ctx.addressSendCodeLoading,
                      phoneSendCodeLoading: _ctx.phoneSendCodeLoading,
                      addressLoading: _ctx.addressLoading,
                      phoneNumberLoading: _ctx.phoneNumberLoading,
                      addressVerifyLoading: _ctx.addressVerifyLoading,
                      phoneNumberVerifyLoading: _ctx.phoneNumberVerifyLoading,
                      tmModel: _ctx.teamMember,
                      formLoading: _ctx.formLoading,
                      onGetContact: _ctx.getContact,
                      onSetAddressAsDefault: _ctx.setAddressAsDefault,
                      onSetPhoneAsDefault: _ctx.setPhoneAsDefault,
                      onVerifyAddress: _ctx.verifyAddress,
                      onVerifyPhone: _ctx.verifyPhone,
                      onSendAddressCode: _ctx.sendAddressCode,
                      onSendPhoneCode: _ctx.sendPhoneCode,
                      onRemoveAddress: _ctx.removeAddress,
                      onScrollToElement: _ctx.scrollToElement,
                      onRemovePhone: _ctx.removePhone,
                      onSaveAddress: _ctx.saveAddress,
                      onSavePhone: _ctx.savePhone
                    }, null, 8, ["onSnackbarMessage", "isHiringmanager", "selectedTeammemberModel", "onShowErrorMessage", "addressList", "phoneList", "addressSendCodeLoading", "phoneSendCodeLoading", "addressLoading", "phoneNumberLoading", "addressVerifyLoading", "phoneNumberVerifyLoading", "tmModel", "formLoading", "onGetContact", "onSetAddressAsDefault", "onSetPhoneAsDefault", "onVerifyAddress", "onVerifyPhone", "onSendAddressCode", "onSendPhoneCode", "onRemoveAddress", "onScrollToElement", "onRemovePhone", "onSaveAddress", "onSavePhone"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.uploadImage,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.uploadImage) = $event)),
      persistent: "",
      "max-width": "600px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { "align-self": "center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { justify: "center" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  class: "secondary_btn mr-5",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.uploadImage = false))
                }, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode("Cancel")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "primary_btn",
                  onClick: _ctx.setImage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("mdi-check")
                      ])),
                      _: 1
                    }),
                    _cache[26] || (_cache[26] = _createTextVNode(" Accept"))
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.showConfirm)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          modelValue: _ctx.showConfirm,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showConfirm) = $event)),
          "max-width": "500",
          onKeydown: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.showConfirm = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_confirm_dialog, {
              message: _ctx.confirmModel,
              onConfirm: _ctx.confirmRemove,
              onCancel: _ctx.cancelRemove
            }, null, 8, ["message", "onConfirm", "onCancel"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.showJobRequestConfirm)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 1,
          modelValue: _ctx.showJobRequestConfirm,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showJobRequestConfirm) = $event)),
          "max-width": "500",
          onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.showJobRequestConfirm = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_confirm_dialog, {
              loading: _ctx.sendReqeustLoading,
              message: _ctx.confirmModel,
              showInput: "",
              inputLable: "Job Title",
              onConfirm: _ctx.confirmSendJobRequest,
              onCancel: _ctx.cancelSendRequest
            }, null, 8, ["loading", "message", "onConfirm", "onCancel"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.showSkillRequestConfirm)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 2,
          modelValue: _ctx.showSkillRequestConfirm,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showSkillRequestConfirm) = $event)),
          "max-width": "500",
          onKeydown: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.showSkillRequestConfirm = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_confirm_dialog, {
              loading: _ctx.sendReqeustLoading,
              message: _ctx.confirmModel,
              showInput: "",
              inputLable: "Skill",
              onConfirm: _ctx.sendAddSkillRequest,
              onCancel: _ctx.cancelSendRequest
            }, null, 8, ["loading", "message", "onConfirm", "onCancel"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}