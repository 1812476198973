<template>
  <v-card title="Job Titles" flat>
    <template v-slot:text>
      <v-text-field
        v-model="searchQuery"
        label="Search"
        placeholder="Search"
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
        clearable
      ></v-text-field>
    </template>

    <v-data-table
      :disable="tableLoading"
      :loading="tableLoading"
      :headers="headers"
      :items="jobTitles"
      item-value="Id"
      :search="searchQuery"
      v-model="selectedJobTitleIds"
      multi-sort
      show-select
    >
      <template v-slot:item.Name="{ index, item }">
        <v-row>
          <v-col cols="10">
            <div v-if="showTextField[index]">
              <v-text-field v-model="item.Name" maxlength="120" dense hide-details></v-text-field>
            </div>
            <div v-else>{{ item.Name }}</div>
          </v-col>
          <v-col>
            <v-badge
              v-if="item.Id && selectedJobTitleIds.includes(item.Id)"
              :color="selectedJobTitleIds.indexOf(item.Id) == 0 ? 'green' : 'error'"
              :content="selectedJobTitleIds.indexOf(item.Id) + 1"
              inline
            >
            </v-badge>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actions="{ index, item }">
        <div class="text-center">
          <v-btn
            v-if="!showTextField[index]"
            class="text-uppercase"
            size="small"
            color="primary"
            text="Edit"
            v-tooltip="'Edit requested job title'"
            @click="showTextField[index] = true"
          ></v-btn>

          <div v-else>
            <v-btn class="mx-1" size="small" color="blue" @click="saveItem(index, item)"> Done </v-btn>
            <v-btn class="mx-1" size="small" color="red" @click="showTextField[index] = false"> Cancel </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-col md="12">
        <v-btn text="Combine Selected" color="secondary" class="mr-5" @click="combineSelectedJobTitlesByAdmin()"> </v-btn>
        <v-btn text="Approve Selected" color="primary" class="" @click="approveSelectedJobTitlesByAdmin()"> </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DetailsApi, DetailsModel, JobTitleModel } from "shared-components/src/services/openApi/api";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      searchQuery: "",
      searchTimeout: null,
      selectedJobTitleIds: [] as string[],
      tableLoading: false,
      showTextField: [] as boolean[],
      jobTitles: [] as JobTitleModel[],
      itemsPerPage: 10,
      headers: [
        { align: "start", key: "Name", title: "Job Title Name", width: "300px"},
        { key: "CreatedAt", title: "Created At", width: "200px" },
        { key: "UpdatedAt", title: "Updated At", width: "200px" },
        { align: "start", key: "RoleName", title: "Role Name", width: "200px" },
        { key: "UserId", title: "UserId", width: "200px" },
        { key: "IsRequested", title: "Is Requested", width: "50px" },
        { align: "center", key: "actions", title: "Actions", sortable: false, width: "200px" },
      ],
    };
  },
  async mounted() {
    await this.fetchJobTitles();
  },
  methods: {
    async approveSelectedJobTitlesByAdmin() {
      if (this.selectedJobTitleIds.length == 0) {
        store.dispatch("showWarningMessage", "Select At least one job title");
        return;
      }
      this.tableLoading = true;
      try {
        await new DetailsApi().approveRequestedJobTitleByAdmin(this.selectedJobTitleIds ?? "");
        this.jobTitles.forEach((element) => {
          if (element.Id && this.selectedJobTitleIds.includes(element.Id)) {
            element.UserId = undefined;
            element.IsRequested = false;
          }
        });
        store.dispatch("showSuccessMessage", "Approved Successfully!");
      } finally {
        this.tableLoading = false;
        this.selectedJobTitleIds = [];
      }
    },
    async combineSelectedJobTitlesByAdmin() {
      if (this.selectedJobTitleIds.length == 0) {
        store.dispatch("showWarningMessage", "Select At least one job title");
        return;
      }
      this.tableLoading = true;
      try {
        var requestBody = [] as DetailsModel[];
        this.jobTitles.forEach((element) => {
          if (element.Id && this.selectedJobTitleIds.includes(element.Id)) {
            requestBody.push({ id: element.Id, Name: element.Name, Type: (this.selectedJobTitleIds.indexOf(element.Id) + 1).toString() });
          }
        });
        await new DetailsApi().combineRequestedJobTitleByAdmin(requestBody);
        store.dispatch("showSuccessMessage", "Updated Successfully!");
      } finally {
        this.tableLoading = false;
        this.selectedJobTitleIds = [];
        await this.fetchJobTitles();
      }
    },
    async fetchJobTitles() {
      this.tableLoading = true;
      try {
        var rsp = await new DetailsApi().getJobTitles();
        this.jobTitles = rsp.data;
        this.showTextField = new Array(this.jobTitles.length).fill(false);
      } finally {
        this.tableLoading = false;
      }
    },
    async saveItem(index: number, jobTitle: JobTitleModel) {
      this.tableLoading = true;
      try {
        var requestBody = { Id: jobTitle.Id, Name: jobTitle.Name } as JobTitleModel;
        await new DetailsApi().updateRequestedJobTitleByAdmin(requestBody);
        store.dispatch("showSuccessMessage", "Updated Successfully!");
      } finally {
        this.tableLoading = false;
        this.showTextField[index] = false;
      }
    },
  },
});
</script>
