<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <span>Changing VM status to {{ status }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel" fab class="error" x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text class="pt-0 pb-0">
      <v-form ref="frmVmStatus" v-model="valid" lazy-validation>
        <v-row class="m-0">
          <v-col class="pt-0" md="12" sm="12" cols="12">
            <DateInputField
              :rules="[validations.required]"
              label="Effective date"
              first-day-of-week="1"
              v-model="statusModel.EffectiveDate"
              hide-actions
              placeholder="Effective date"
              prepend-icon=""
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn variant="text" color="secondary_btn mr-5" @click="cancel"> cancel</v-btn>
      <v-btn class="primary_btn" @click="changeStatus" :loading="loading"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import validations from "shared-components/src/utils/validations";
import { VmStatusRequestModel } from "shared-components/src/services/openApi/api";

export default defineComponent({
  props: ["vmId", "status"],
  data() {
    return {
      validations,
      valid: true,
      loading: false,
      statusModel: {
        EffectiveDate: new Date() as Date | null,
        vmId: "",
      },
    };
  },
  mounted() {
    this.statusModel.vmId = this.vmId;
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    async changeStatus() {
      if (this.statusModel.vmId) {
        const validation = await (this.$refs.frmVmStatus as any).validate();
        if (validation.valid) {
          var model = {
            VmId: this.statusModel.vmId,
            EffectiveDate: this.statusModel.EffectiveDate?.toDateString(),
          } as VmStatusRequestModel;
          this.loading = true;
          this.$emit("confirmed", model);
        }
      }
    },
  },
});
</script>
