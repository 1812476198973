<template>
  <v-card>
    <v-card-text>
      <v-col md="12">
        <v-data-table-server
          :items="actionLogs"
          :headers="headers"
          :loading="loading"
          :page="page"
          :items-per-page="numberOfPages"
          @update:options="(event: any) => tableOptions = event"
          :items-length="totalItemsCount"
          item-key="Id"
        >
          <template v-slot:item.UpdatedAt="{ item }"> {{ formatDate(item.UpdatedAt) }}</template>
          <template v-slot:item.EffectiveDate="{ item }"> {{ formatDate(item.EffectiveDate) }}</template>
          <template v-slot:item.NewStatus="{ item }"> {{ item.NewStatus }}</template>
          <template v-slot:item.Comment="{ item }"> {{ item.Comment }}</template>
        </v-data-table-server>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-btn variant="text" class="secondary_btn" @click="cancel"> cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { PaginationRequestModel, VmActionLogResponseModel, VMApi } from "shared-components/src/services/openApi";
import Utils from "shared-components/src/utils/Utils";
var vmApi = new VMApi();

export default defineComponent({
  props: ["vmId"],
  data() {
    return {
      actionLogs: [] as any[] | undefined,
      tableOptions: {} as any,
      page: 1,
      totalItemsCount: 0 as any,
      numberOfPages: 10,
      sortProp: "UpdatedAt",
      sortOrder: "desc",
      valid: true,
      loading: false,
      headers: [
        { title: "Update date", value: "UpdatedAt" },
        { title: "Effective date", value: "EffectiveDate" },
        { title: "Status", value: "NewStatus" },
        { title: "Comment", value: "Comment" },
      ],
    };
  },
  async mounted() {
    await this.fetchActionLogs();
  },

  watch: {
    tableOptions(newVal: any, oldValue: any) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0].key;
        }

        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "asc";
        }
        this.fetchActionLogs();
      }
    },
  },
  methods: {
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      }
      return "";
    },
    async fetchActionLogs() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;
        const paginationModel = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
        } as PaginationRequestModel;
        var result = await vmApi.getActionLog(this.vmId, paginationModel);
        if (result.data) {
          this.actionLogs = [...(result.data.Items as any)] as VmActionLogResponseModel[];
          this.totalItemsCount = result.data.Total;
        }
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit("close");
    },
  },
});
</script>
