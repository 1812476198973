import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[6] || (_cache[6] = _createElementVNode("span", null, " Renew Commitment", -1)),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "",
                dark: "",
                onClick: _ctx.cancel,
                fab: "",
                class: "error",
                "x-small": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "s",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DateInputField, {
                        "first-day-of-week": "1",
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.cloneStartDate,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cloneStartDate) = $event)),
                        label: "Start date",
                        outlined: "",
                        dense: "",
                        "hide-actions": "",
                        placeholder: "Select Date",
                        "prepend-icon": ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DateInputField, {
                        "first-day-of-week": "1",
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.cloneEndDate,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cloneEndDate) = $event)),
                        label: "End date",
                        outlined: "",
                        dense: "",
                        "hide-actions": "",
                        placeholder: "Select Date",
                        "prepend-icon": ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "secondary_btn mr-5",
            dark: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clone(true)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("mdi-refresh")
                ])),
                _: 1
              }),
              _cache[8] || (_cache[8] = _createTextVNode(" Clone and expire old"))
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            class: "primary_btn",
            dark: "",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clone()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("mdi-content-copy")
                ])),
                _: 1
              }),
              _cache[10] || (_cache[10] = _createTextVNode(" Clone Only"))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}