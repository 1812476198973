import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_data_table_server, {
                items: _ctx.actionLogs,
                headers: _ctx.headers,
                loading: _ctx.loading,
                page: _ctx.page,
                "items-per-page": _ctx.numberOfPages,
                "onUpdate:options": _cache[0] || (_cache[0] = (event) => _ctx.tableOptions = event),
                "items-length": _ctx.totalItemsCount,
                "item-key": "Id"
              }, {
                "item.UpdatedAt": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(item.UpdatedAt)), 1)
                ]),
                "item.EffectiveDate": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(item.EffectiveDate)), 1)
                ]),
                "item.NewStatus": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.NewStatus), 1)
                ]),
                "item.Comment": _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.Comment), 1)
                ]),
                _: 1
              }, 8, ["items", "headers", "loading", "page", "items-per-page", "items-length"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            variant: "text",
            class: "secondary_btn",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" cancel")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}