<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title> Loan Approval </v-card-title>
    <v-card-text>
      <v-form ref="frmApproval" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="6">
            <TextField
              :rules="[rules.required]"
              v-model="formatedProposedDate"
              label="Requested Start Date"
              readonly
              dense
              outlined
              :disabled="true"
            ></TextField>
          </v-col>
          <v-col md="6">
            <v-switch class="mt-6 pl-5" label="Reject Request" v-model="model.IsRejected" color="red"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" v-if="model.IsRejected">
            <TextField v-model="model.RejectionReason" dense outlined label="Rejection Reason"></TextField>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="secondary_btn mr-5" @click="cancel"> cancel</v-btn>

      <v-btn class="primary_btn" @click="set"><v-icon>mdi-check</v-icon> {{ model.IsRejected ? "Reject" : "Approve" }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import { LoanApprovalModel } from "shared-components/src/models/Loan";
export default defineComponent({
  props: ["loading", "value", "ProposedPaymentDate"],
  data() {
    return {
      valid: true,
      startMenuForProposedDate: false,
      startMenuForPaymentDate: false,
      endMenu: false,
      model: this.value as LoanApprovalModel,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
        currency: (v: any) => !v || /^(\-|\+)?[0-9,]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
      },
    };
  },
  methods: {
    resetModel(): void {
      this.model = this.value;
    },
    cancel(): void {
      this.$emit("cancel");
    },
    async set(): Promise<void> {
      const isValid = await (this.$refs.frmApproval as any).validate();
      if (isValid.valid) {
        this.$emit("approveLoan", this.model);
      }
    },
  },
  computed: {
    formatedPaymentDate() {
      return this.model.StartPaymentDate ? Utils.toVsDateFormat(this.model.StartPaymentDate) : null;
    },
    formatedProposedDate() {
      return this.ProposedPaymentDate ? Utils.toVsDateFormat(this.ProposedPaymentDate) : null;
    },
  },
});
</script>
