<template>
  <v-card :loading="loading">
    <v-card-title> {{ vmModel && vmModel.Id ? "Edit Vm" : "Register Vm" }} </v-card-title>
    <v-card-text>
      <v-form ref="frmRegister" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="4">
            <AutoCompleteField
              :rules="[rules.required]"
              v-model="selectedTeammember"
              :items="teammemberList"
              item-title="FullName"
              item-value="Id"
              outlined
              dense
              label="Team Member"
              return-object
            >
              <template v-slot:item="{ item, index, props }">
                <v-list>
                  <v-list-item v-bind="props" :class="{ 'txt-color': item.raw.isFirstInactive }" :title="item.raw.FullName"></v-list-item>
                </v-list>
              </template>
            </AutoCompleteField>
          </v-col>
          <v-col md="4">
            <TextField :rules="[rules.required]" outlined dense label="User Name" placeholder="User Name" v-model="model.Username" />
          </v-col>
          <v-col md="4">
            <TextField :rules="[rules.required]" outlined dense label="Password" placeholder="Password" v-model="model.TempPassword" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <TextField :rules="[rules.required]" outlined dense label="Public IP" placeholder="Public IP" v-model="model.PublicIP" />
          </v-col>
          <v-col md="4">
            <TextField :rules="[rules.required]" outlined dense label="Private IP" placeholder="Private IP" v-model="model.PrivateIP" />
          </v-col>
          <v-col md="4">
            <TextField type="number" :rules="[rules.required]" outlined dense label="Port" placeholder="Port" v-model="model.RemotePortRdp" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <DateInputField
              label="Provisioned On"
              first-day-of-week="1"
              v-model="model.ProvisionedOn"
              hide-actions
              placeholder="Provisioned On"
              prepend-icon=""
            />
          </v-col>
          <v-col md="4">
            <TextField type="number" :rules="[rules.required]" outlined dense label="CPU" placeholder="CPU" v-model="model.CPU" />
          </v-col>
          <v-col md="4">
            <TextField type="number" :rules="[rules.required]" outlined dense label="RAM" placeholder="RAM" v-model="model.RAM" />
          </v-col>
          <v-col md="4">
            <TextField outlined dense label="Wazuh Agent Id" placeholder="Wazuh Agent Id" v-model="model.WazuhAgentId" />
          </v-col>
          <v-col md="4">
            <AutoCompleteField label="Wazuh Level" v-model="model.WazuhLevel" :items="wazuhLevels" multiple outlined chips dense />
          </v-col>

          <v-col md="4">
            <TextField outlined dense label="Wazuh Policy Id" placeholder="Wazuh Policy Id" v-model="model.WazuhPolicyId" />
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col md="4">
            <TextField type="number" :rules="[rules.required]" outlined dense label="Storage" placeholder="Storage" v-model="model.Storage" />
          </v-col>
          <v-col md="4">
            <v-checkbox hide-details v-model="model.Activitywatch" label="Activitywatch"></v-checkbox>
          </v-col>
          <v-col md="4">
            <v-checkbox hide-details v-model="model.AdminAccess" label="AdminAccess"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-checkbox hide-details v-model="model.CodeClanAgent" label="CodeClanAgent"></v-checkbox>
          </v-col>
          <v-col md="4">
            <v-checkbox hide-details v-model="model.GuestAgentRquired" label="GuestAgent"></v-checkbox>
          </v-col>
          <v-col md="4">
            <v-checkbox hide-details v-model="model.WazuhAgent" label="WazuhAgent"></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn variant="text" class="secondary_btn mr-5" @click="cancel" :loading="loading"> cancel</v-btn>
      <v-btn class="primary_btn" @click="save" :loading="loading"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import { VM } from "shared-components/src/models/VM";
import { Teammember } from "shared-components/src/models/Teammember";
import rules from "shared-components/src/utils/validations";
import moment from "moment";
export default defineComponent({
  props: ["vmModel", "teammembers", "loading"],
  mounted() {
    if (this.vmModel && this.vmModel.Id && this.vmModel.Id.length > 0) {
      this.model = this.vmModel;
      this.model.ProvisionedOn = moment(this.model.ProvisionedOn).toDate();
      this.selectedTeammember = this.teammembers.filter((item: any) => item.Id == this.model.OwnerId)[0];
    }
  },
  data() {
    return {
      wazuhLevels: ["L1", "L2", "L3", "L4", "L5"],
      valid: true,
      teammemberList: this.teammembers.filter((x: any) => x.FullName != "All"),
      selectedTeammember: undefined as Teammember | undefined,
      endMenu: false,
      model: {} as VM,
      formLoading: false,
      rules,
    };
  },
  methods: {
    resetModel(): void {
      this.model = {} as VM;
    },
    formatNumber(value: number): string {
      return Utils.moneyFormat(value);
    },
    cancel(): void {
      this.$emit("cancel");
      this.reset();
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmRegister as any).validate();
      if (isValid.valid && this.selectedTeammember) {
        this.formLoading = true;
        this.model.OwnerId = this.selectedTeammember.Id;
        this.$emit("save", this.model);
        this.reset();
      }
    },
    reset() {
      this.model = {} as VM;
      (this.$refs.frmRegister as any).reset();
    },
  },
  computed: {
    isValidTeamMember(): boolean {
      if (this.model.OwnerId) {
        return true;
      }
      return false;
    },
  },
  watch: {
    vmModel: {
      async handler(newVal) {
        if (newVal == null) {
          this.reset();
        } else {
          this.model = newVal;
          this.selectedTeammember = this.teammembers.filter((item: any) => item.Id == this.model.OwnerId)[0];
        }
      },
      deep: true,
    },
  },
});
</script>
