<template>
  <v-row>
    <v-col v-show="!pageLoading" md="12">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          <v-row no-gutters>
            Commitment List
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn id="create-new-commitment-btn" color="primary" dark @click="newItem"> <v-icon>mdi-plus</v-icon> New </v-btn>
            <v-dialog v-if="showNewItem" v-model="showNewItem" persistent fullscreen @keydown.esc="showNewItem = false">
              <NewCommitmentV2
                ref="newCommitment"
                :customers="customers"
                :teammembers="teammembers"
                :officeSpaces="officeSpaces"
                :lineManagers="lineManagers"
                :portfolioManagers="portfolioManagers"
                :projectLeads="projectLeads"
                :publicHolidayZones="publicHolidayZones"
                :tdms="tdms"
                :commitments="desserts"
                :selectedCommitmentId="selectedCommitmentId"
                @cancel="showNewItem = false"
                @saved="commitmentSaved"
              ></NewCommitmentV2>
            </v-dialog>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="align-center">
            <v-col md="2">
              <SelectField
                :items="CustomerList"
                item-title="Name"
                item-value="id"
                v-model="selectedCustomer"
                label="Customer"
                outlined
                dense
                hide-details
                @update:modelValue="onChangeCustomer"
              />
            </v-col>
            <v-col md="2">
              <SelectField
                :loading="projectLoading"
                hide-details
                :items="ProjectList"
                item-title="Name"
                item-value="id"
                v-model="selectedProject"
                label="Project"
                outlined
                dense
              />
            </v-col>
            <v-col md="2">
              <SelectField
                :items="TeammemberList"
                item-title="Name"
                item-value="id"
                v-model="selectedTeammember"
                label="Team Member"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col md="2">
              <SelectField
                :items="LinemanagerList"
                item-title="Name"
                item-value="id"
                v-model="selectedLineManager"
                label="Line Manager"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col md="2">
              <SelectField :items="statusItems" item-title="title" item-value="value" v-model="status" label="Status" outlined dense hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn color="secondary" class="mr-4" @click="clearFilters"> <v-icon left>mdi-close</v-icon> Clear </v-btn>
              <v-btn color="primary" @click="fetchCommitments"><v-icon left>mdi-check</v-icon> Filter </v-btn>
            </v-col>
          </v-row>
          <v-data-table-server
            item-key="Id"
            class="mt-5"
            :items="tempDesserts"
            :headers="headers"
            :loading="loading"
            :custom-sort="customSort"
            :items-per-page="numberOfPages"
            :items-length="totalItemsCount"
            @update:options="(event: any) => tableOptions = event"
          >
            <template v-slot:item="{ item }">
              <tr :class="{ 'inactive-row': checkCommitmentStatus(item.StatusV2)['index'] === 2 }">
                <td>
                  <v-badge
                    v-tooltip="'Remained Days'"
                    v-if="checkDateInRange(item.EndDate)"
                    color="error"
                    :content="remainingDaysTillCommitmentExpiry(item.EndDate)"
                    inline
                  >
                    <strong class="mr-4">{{ item.Name }}</strong>
                  </v-badge>
                  <strong v-else class="mr-4">{{ item.Name }}</strong>
                </td>
                <td>{{ formatCustomers(item) }}</td>
                <td>{{ formatProjects(item) }}</td>
                <td>{{ formatTeammembers(item) }}</td>
                <td>{{ formatDate(item.StartDate) }}</td>
                <td>{{ formatDate(item.EndDate) }}</td>
                <td>
                  <v-chip class="ma-2" :color="checkCommitmentStatus(item.StatusV2)['color']" variant="flat">{{
                    checkCommitmentStatus(item.StatusV2)["value"]
                  }}</v-chip>
                </td>
                <td>
                  <v-tooltip :text="checkApprovalStatus(item.AdminApproval)['value']">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" :color="checkApprovalStatus(item.AdminApproval)['color']">mdi-radiobox-marked</v-icon>
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip :text="checkApprovalStatus(item.CustomerApproval)['value']">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" :color="checkApprovalStatus(item.CustomerApproval)['color']">mdi-radiobox-marked</v-icon>
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip :text="checkApprovalStatus(item.TeamMemberApproval)['value']">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" :color="checkApprovalStatus(item.TeamMemberApproval)['color']">mdi-radiobox-marked</v-icon>
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-menu offset-y location="bottom" scroll-strategy="close">
                    <template v-slot:activator="{ props }">
                      <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
                    </template>
                    <v-list>
                      <v-list-item @click="edit(item.id)" title="Edit" />
                      <v-list-item @click="remove(item)" title="Delete" />
                      <v-list-item @click="clone(item)" title="Clone" />
                      <v-list-item @click="showReview(item.id)" title="Review" />
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false">
      <confirm-dialog :message="confirmModel" @confirm="confirmRemove" @cancel="cancelRemove"></confirm-dialog>
    </v-dialog>
    <v-dialog v-if="ShowClone" v-model="ShowClone" persistent max-width="500" @keydown.esc="ShowClone = false">
      <clone-commitment ref="cloneCommitment" :commitment="cloningItem" @cancel="ShowClone = false" @clone="cloneCommitment"></clone-commitment>
    </v-dialog>
    <v-dialog v-if="showReviewModal" v-model="showReviewModal" persistent max-width="1000" @keydown.esc="ShowClone = false">
      <ReviewCommitment :commitmentId="selectedCommitmentId" @close="closeReview()" @updated="closeReview()" />
    </v-dialog>

    <loading-component v-if="pageLoading"></loading-component>
  </v-row>
</template>
<script lang="ts">
import Commitment from "shared-components/src/models/Commitment";
import NewCommitmentV2 from "./NewCommitmentV2.vue";
import CloneCommitment from "./CloneCommitment.vue";
import CommitmentService from "shared-components/src/services/CommitmentService";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import Infrastructure from "shared-components/src/utils/Infrastructure";
import {
  ProjectApi,
  PaginationRequestModelV2,
  CommitmentApi,
  OfficeSpaceModel,
  CommitmentModelStatusV2Enum,
} from "shared-components/src/services/openApi/api";
import store from "@/store";
import UrlHelper from "shared-components/src/utils/UrlHelper";
import { CommitmentModel } from "shared-components/src/services/openApi";
import { OfficeSpaceApi } from "shared-components/src/services/openApi";
import { PublicHolidaysApi } from "shared-components/src/services/openApi";
import { LineManagersApi } from "shared-components/src/services/openApi";
import { ProjectLeadsApi } from "shared-components/src/services/openApi";
import { PortfolioManagersApi } from "shared-components/src/services/openApi";
import { TDMApi } from "shared-components/src/services/openApi";
import { CustomerApi } from "shared-components/src/services/openApi";
import { TeammemberApi } from "shared-components/src/services/openApi";
import ReviewCommitment from "./ReviewCommitment.vue";
import { CommitmentModelAdminApprovalEnum } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    ConfirmDialog,
    LoadingComponent,
    CloneCommitment,
    NewCommitmentV2,
    ReviewCommitment,
  },
  data() {
    return {
      tableOptions: {} as any,
      totalItemsCount: 0 as number | string,
      sortProps: [] as Array<{ [key: string]: string }>,
      numberOfPages: 10,
      statusItems: [
        {
          title: "All",
          value: "All",
        },
        {
          title: "Active",
          value: "Active",
        },
        {
          title: "Expired",
          value: "Expired",
        },
        {
          title: "Pending",
          value: "Pending",
        },
        {
          title: "Rejected",
          value: "Rejected",
        },
        {
          title: "Upcoming",
          value: "Upcoming",
        },
      ],
      ShowClone: false,
      pageLoading: true,
      totalDesserts: 0,
      editItem: null as CommitmentModel | null,

      publicHolidayZones: [] as Array<{ id: string; Name: string }>,
      lineManagers: [] as Array<{ id: string; Name: string }>,
      projectLeads: [] as Array<{ id: string; Name: string }>,
      portfolioManagers: [] as Array<{ id: string; Name: string }>,
      tdms: [] as Array<{ id: string; Name: string }>,
      projects: [] as Array<{ id: string; Name: string }>,
      customers: [] as Array<{ id: string; Name: string }>,
      teammembers: [] as Array<{ id: string; Name: string }>,
      officeSpaces: [] as OfficeSpaceModel[],

      desserts: [] as CommitmentModel[],
      tempDesserts: [] as CommitmentModel[],
      cloningItem: null as CommitmentModel | null,
      selectedLineManager: "",
      selectedTeammember: "",
      selectedCustomer: "",
      selectedProject: "",
      status: "All",
      confirmModel: {
        title: "",
        text: "",
      },
      loading: true,
      showNewItem: false,
      showConfirm: false,
      headers: [
        { title: "Name", sortable: true, value: "Name", align: "start" },
        { title: "Customers", value: "Customers", sortable: false, align: "center" },
        { title: "Projects", value: "Projects", sortable: false, align: "center" },
        { title: "TeamMembers", sortable: false, value: "TeamMembers", align: "center" },
        { title: "Start date", sortable: true, value: "StartDate", align: "center" },
        { title: "End date", sortable: true, value: "EndDate", align: "center" },
        { title: "Status", sortable: false, value: "Status", align: "center" },
        {
          title: "ApprovalStatus",
          children: [
            { title: "Admin", value: "AdminApproval", align: "center" },
            { title: "Customer", value: "CustomerApproval", align: "center" },
            { title: "TeamMember", value: "TeamMemberApproval", align: "center" },
          ],
          align: "center",
        },
        { title: "Actions", sortable: false, value: "Actions", align: "end" },
      ],
      selectedItem: null as CommitmentModel | null,
      selectedId: null as string | null,
      filters: {} as { [key: string]: string },
      projectLoading: false,
      selectedCommitmentId: null as string | null,
      showReviewModal: false,
    };
  },
  async mounted() {
    try {
      this.pageLoading = true;
      await this.fetchCommitments();

      await this.fetchPublicHolidays();
      await this.fetchCustomers();
      await this.fetchTeammembers();
      await this.fetchLineManager();
      await this.fetchProjectLeads();
      await this.fetchPortfolioManagers();
      await this.fetchTDMs();
      await this.fetchOfficeSpaces();
      this.checkAndLoadUrlItems();
    } finally {
      this.pageLoading = false;
    }
  },
  methods: {
    checkDateInRange(endDate: string | undefined): boolean {
      if (endDate) {
        const dateToCheck = Utils.stringUtcToLocalDate(endDate);
        const currentDate = new Date();
        const oneMonthLater = new Date();
        oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
        if (dateToCheck >= currentDate && dateToCheck <= oneMonthLater) return true;
      }
      return false;
    },
    remainingDaysTillCommitmentExpiry(endDate: string | undefined): number | undefined {
      if (endDate) {
        const dateToCheck = Utils.stringUtcToLocalDate(endDate);
        const currentDate = new Date();
        const timeDiff = dateToCheck.getTime() - currentDate.getTime();
        const dayInMilliseconds = 1000 * 60 * 60 * 24;
        return Math.ceil(timeDiff / dayInMilliseconds);
      }
    },
    showReview(commitmentId: string) {
      this.selectedCommitmentId = commitmentId;
      this.showReviewModal = true;
    },
    closeReview() {
      this.showReviewModal = false;
      this.selectedCommitmentId = null;
      this.fetchCommitments();
    },
    async onChangeCustomer() {
      await this.fetchProjectsByCustomer();
    },
    async fetchProjectsByCustomer() {
      try {
        this.projectLoading = true;
        this.projects = [];
        if (this.selectedCustomer) {
          this.projects = (await new ProjectApi().getByCustomerId(this.selectedCustomer)).data.map((c: any) => {
            return { id: c.id, Name: c.Name };
          });
        }
      } finally {
        this.projectLoading = false;
      }
    },
    async fetchOfficeSpaces() {
      this.officeSpaces = (await new OfficeSpaceApi().getOfficeSpace()).data;
    },
    async fetchPublicHolidays() {
      this.publicHolidayZones = (await new PublicHolidaysApi().getPublicHolidayList()).data.map((item: any) => {
        return { id: item.Id, Name: item.Title };
      });
    },
    async fetchLineManager() {
      this.lineManagers = (await new LineManagersApi().getLineManagerList()).data.map((item: any) => {
        return { id: item.id, Name: item.FirstName + " " + item.LastName };
      });
    },
    async fetchProjectLeads() {
      this.projectLeads = (await new ProjectLeadsApi().getProjectLeadList()).data.map((item: any) => {
        return { id: item.id, Name: item.FirstName + " " + item.LastName };
      });
    },
    async fetchPortfolioManagers() {
      this.portfolioManagers = (await new PortfolioManagersApi().getPortfolioManagerList()).data.map((item: any) => {
        return { id: item.id, Name: item.FirstName + " " + item.LastName };
      });
    },
    async fetchTDMs() {
      this.tdms = (await new TDMApi().getTDMs()).data.map((item: any) => {
        return { id: item.id, Name: item.FirstName + " " + item.LastName };
      });
    },
    async fetchCustomers() {
      this.customers = (await new CustomerApi().getCustomers()).data.map((item: any) => {
        return { id: item.id, Name: item.FirstName + " " + item.LastName };
      });
    },
    async fetchTeammembers() {
      this.teammembers = (await new TeammemberApi().getTeammemberListSummaryDetails()).data.map((item: any) => {
        return { id: item.Id, Name: item.FirstName + " " + item.LastName };
      });
    },
    async checkAndLoadUrlItems() {
      const urlItems = UrlHelper().notification().getUrlHashItems();
      if (urlItems.id && urlItems.action == "view") {
        this.edit(urlItems.id);
      }
      if (urlItems.id && urlItems.action == "review") {
        this.showReview(urlItems.id);
      }
    },
    getCommitmentObject(item: any) {
      var commitment = { ...item.commitment };
      var customerNamesArray = [] as string[];
      var projectNamesArray = [] as string[];
      var teammemberNamesArray = [] as string[];
      this.customers.forEach((c) => {
        if (commitment.CustomerIds.includes(c.id)) {
          customerNamesArray.push(c.Name);
        }
      });
      this.projects.forEach((c) => {
        if (commitment.ProjectIds.includes(c.id)) {
          projectNamesArray.push(c.Name);
        }
      });
      this.teammembers.forEach((c, index) => {
        if (commitment.TeamMemberIds.includes(c.id)) {
          teammemberNamesArray.push(c.Name);
        }
      });
      if (commitment.StartDate && commitment.EndDate) {
        var customerNames = customerNamesArray.join(", ");
        var projectNames = projectNamesArray.join(", ");
        var teammemberNames = teammemberNamesArray.join(", ");
        commitment.Name = `${customerNames}-${projectNames}-${Utils.getYear(commitment.StartDate)}-${Utils.getMonthName(
          commitment.StartDate
        )}-${Utils.getMonthName(commitment.EndDate)}-${teammemberNames}`;
      }
      return commitment;
    },
    async cloneCommitment(cloneRes: any) {
      var commitmentObject = this.getCommitmentObject(cloneRes);
      if (commitmentObject) {
        if (cloneRes.expireOld) {
          var updateResult = await CommitmentService.update(commitmentObject);
          this.commitmentSaved(updateResult.id);
        } else {
          commitmentObject.id = "";
        }
        this.editItem = commitmentObject;
        this.ShowClone = false;
        this.showNewItem = true;
        setTimeout(async () => {
          await (this.$refs.newCommitment as any).loadData();
        }, 500);
      }
    },
    clone(model: any) {
      this.cloningItem = model;
      this.ShowClone = true;
    },
    remove($item: CommitmentModel): void {
      this.confirmModel.title = `Remove Commitment(${$item.Name})`;
      this.confirmModel.text = "Are you sure?";
      this.showConfirm = true;
      this.selectedItem = $item;
    },
    async confirmRemove(): Promise<void> {
      this.showConfirm = false;
      if (this.selectedItem) {
        const idx = this.desserts.findIndex((x) => x.id === (this.selectedItem ? this.selectedItem.id : ""));
        const tempIdx = this.tempDesserts.findIndex((x) => x.id === (this.selectedItem ? this.selectedItem.id : ""));
        const result = await CommitmentService.remove(this.selectedItem.id ?? "");
        if (result) {
          this.desserts.splice(idx, 1);
          this.tempDesserts.splice(tempIdx, 1);
          this.selectedItem = null;
        } else {
          store.dispatch(
            "showErrorMessage",
            "you can't delete this commitment because there is one or more timesheet associated to this commitment!"
          );
        }
      }
    },
    cancelRemove(): void {
      this.showConfirm = false;
      this.selectedItem = null;
    },
    async fetchCommitments(): Promise<void> {
      this.loading = true;
      const { page, itemsPerPage } = this.tableOptions;
      const model = {
        PageNumber: page,
        PageSize: itemsPerPage,
        SortProps: this.sortProps,
        Filters: this.filters,
      } as PaginationRequestModelV2;
      try {
        const result = await new CommitmentApi().getCommitmentsV2(model);
        this.desserts = result.data.Items ?? [];
        this.totalItemsCount = result.data.Total ?? 0;
        this.tempDesserts = this.desserts;
      } finally {
        this.loading = false;
      }
    },
    formatTeammembers(item: CommitmentModel): string {
      return item && item.TeamMembers ? item.TeamMembers.map((item: any) => item.TmFullName).join(", ") : "";
    },
    formatProjects(item: CommitmentModel): string {
      return item && item.ProjectDetails ? item.ProjectDetails.map((item: any) => item.Name).join(", ") : "";
    },
    formatCustomers(item: CommitmentModel): string {
      return item && item.Customers ? item.Customers.map((item: any) => item.CuFullName).join(", ") : "";
    },
    isActiveTeammember(teammember: any, activeCommitments: any) {
      return activeCommitments.some((c: any) => c.TeamMemberIds && c.TeamMemberIds.includes(teammember.Id));
    },
    checkCommitmentStatus(item: CommitmentModelStatusV2Enum | undefined) {
      return Infrastructure.checkCommitmentStatus(item);
    },
    checkApprovalStatus(item: CommitmentModelAdminApprovalEnum | undefined) {
      return Infrastructure.checkApprovalStatus(item);
    },
    formatDate(date: string | undefined): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    async edit(id: string): Promise<void> {
      this.selectedCommitmentId = id;
      this.showNewItem = true;
    },
    newItem(): void {
      this.selectedCommitmentId = null;
      this.showNewItem = true;
      setTimeout(async () => {
        await (this.$refs.newCommitment as any).loadData();
      }, 500);
    },
    commitmentSaved(id: string | null | undefined): void {
      if (!id) {
        store.dispatch("showErrorMessage", "you can't edit End Date because there is one or more timesheet after new End Date!");
      } else {
        this.showNewItem = false;
        this.fetchCommitments();
      }
    },
    async clearFilters() {
      this.selectedTeammember = "";
      this.selectedCustomer = "";
      this.selectedProject = "";
      this.selectedLineManager = "";
      this.status = "";

      await this.fetchCommitments();
    },
    customSort(items: any, index: any, isDesc: any) {
      items.sort((a: any, b: any) => {
        if (index[0] === "StartDate" || index[0] === "EndDate") {
          if (!isDesc[0]) {
            return new Date(a[index]).getTime() - new Date(b[index]).getTime();
          } else {
            return new Date(b[index]).getTime() - new Date(a[index]).getTime();
          }
        }
        if (index[0] === "Status") {
          if (!isDesc[0]) {
            return this.checkCommitmentStatus(a)["index"] < this.checkCommitmentStatus(b)["index"] ? -1 : 1;
          } else {
            return this.checkCommitmentStatus(b)["index"] < this.checkCommitmentStatus(a)["index"] ? -1 : 1;
          }
        } else if (typeof a[index] !== "undefined") {
          if (index[0] === "SeniorityLevel") {
            if (!isDesc[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
            }
          } else {
            if (index[0] === "Name") {
              if (!isDesc[0]) {
                return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              } else {
                return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
        }
      });
      return items;
    },
  },
  computed: {
    CustomerList() {
      return [{ id: "", Name: "All" }].concat(this.customers);
    },
    ProjectList() {
      return [{ id: "", Name: "All" }].concat(this.projects);
    },
    TeammemberList() {
      return [{ id: "", Name: "All" }].concat(this.teammembers);
    },
    LinemanagerList() {
      return [{ id: "", Name: "All" }].concat(this.lineManagers);
    },
  },
  watch: {
    selectedCustomer(newVal) {
      if (newVal) {
        this.filters["customerId"] = newVal;
      } else {
        delete this.filters["customerId"];
      }
    },
    selectedProject(newVal) {
      if (newVal) {
        this.filters["projectId"] = newVal;
      } else {
        delete this.filters["projectId"];
      }
    },
    selectedTeammember(newVal) {
      if (newVal) {
        this.filters["teammemberId"] = newVal;
      } else {
        delete this.filters["teammemberId"];
      }
    },
    selectedLineManager(newVal) {
      if (newVal) {
        this.filters["linemanagerId"] = newVal;
      } else {
        delete this.filters["linemanagerId"];
      }
    },
    status(newVal: string) {
      if (newVal) {
        this.filters["status"] = newVal;
      } else {
        delete this.filters["status"];
      }
    },
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProps = newVal.sortBy;
        }
        this.fetchCommitments();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";

.inactive-row {
  background-color: $c_white_smoke;
}
</style>
